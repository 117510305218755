#customers {
  border-collapse: collapse; /* Make table borders collapse */
  background-color: #fff; /* Set white background */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Create a light shadow */
  width: 100%;
}

#customers th {
  font-size: 11px;
  font-weight: 600;
}

#customers td {
  font-size: 12px;
}

#customers th,
#customers td {
  padding: 5px; /* Add padding for spacing */
}

#customers tr:hover {
  background-color: #f9f8f8; /* Gray background on hover */
}

.corporate-explorer-grid-table-guide-column {
  display: flex;
  justify-content: center;
  height: 100%;
  gap: 3px;
}

.corporate-explorer-grid-table-guide-row {
  border: 1px solid #e7e6e6; /* Set border style, width, and color */
}

.corporate-explorer-grid-table-guide-row-vertical-align-top {
  vertical-align: top;
}

.corporate-explorer-grid-name-header:hover span {
  text-decoration: underline;
}
