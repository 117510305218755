/* Styles for the slider component */
.slider {
  height: 10px;
  width: 100%;
  background-color: #d1d1d1;
  border-radius: 5px;
  margin-top: 10px;
}
.main-slider {
  width: 100%;
}
.slider .thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: linear-gradient(180deg, #0466d1 7.81%, #00d1a9 117.33%);
  cursor: grab;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-top: -5px;
}

#minYear,
#maxYear {
  border-radius: 5px;
  /* width: 30px; */
  border: 2px solid #d3d4d4;
}
.company-filter-date-range {
  font-weight: bold;
}
