.probe_input_use_form_error_main_div_final_check {
  min-height: 10px;
  margin: 2px;
  width: 100%;
}

.probe_input_use_form_error_main_div_final_check_error {
  color: red;
  font-size: 10px;
}

.one_final_check_header {
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
}

.one_final_check_box_main_div_for_checkbox_content {
  display: flex;
  width: "100%";
  justify-content: space-between;
  padding: 5px;
  margin: 4px;
  border: 1px dotted #d3cece;
}

.one_final_check_box_content {
  font-size: 12px;
}

@media (max-width: 1200px) {
  .one_final_check_box_content {
    font-size: 10px;
  }
}

@media (max-width: 450px) {
  .one_final_check_header {
    padding: 10px;
    font-size: 10px;
    font-weight: 600;
  }
}
