.searchbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background-color: white;
  gap: 48px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
}

.searchbar .search-name-div {
  display: flex;
  align-items: center;
  flex: 1;
}

.searchbar .search-name-div .input-div {
  width: 100%;
}

.searchbar .search-name-div .label-text {
  min-width: fit-content;
  color: #021b41;
  font-size: 1.25em;
}

.age-label {
  margin-right: 40px;
  color: #021b41;
  font-weight: 500;
  line-height: 1.5em;
  font-size: 1.25em;
}

.searchbar .search-name-div input {
  padding: 17px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  outline: none;
  max-width: 360px;
  width: 100%;
  margin-left: 16px;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5em;

  color: #212529;
}

.search-name-div.range-slider {
  margin-left: 28px;
}

@media (max-width: 1440.99px) {
  .label-text {
    margin-right: 0;
  }

  .searchbar {
    gap: 25px;
  }

  .searchbar .search-name-div input {
    margin-left: 6px;
    padding: 12px;
  }

  .search-name-div.range-slider {
    margin-left: 20px;
  }

  .MuiBox-root.css-1v5z18m {
    width: 250px;
  }
}

@media (max-width: 1024.99px) {
  .searchbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .searchbar .search-name-div .label-text {
    max-width: 120px;
    width: 100%;
  }

  .searchbar .search-name-div {
    width: 100%;
  }

  .MuiBox-root.css-1v5z18m {
    width: 90%;
  }

  .searchbar .search-name-div input {
    max-width: 90%;
  }

  .search-name-div.range-slider {
    margin-left: 0px;
  }

  .searchbar .search-name-div .label-text {
    min-width: fit-content;
    /* font-size: 16px; */
    margin-right: 0px;
  }

  .searchbar .search-name-div input {
    /* font-size: 12px; */
  }

  .age-label {
    /* font-size: 16px; */
    margin-right: 25px;
  }
}

@media (max-width: 768px) {
  .searchbar .search-name-div .label-text {
    margin-right: 0px;
    max-width: 92px;
  }

  .age-label {
    margin-right: 10px;
  }
}
