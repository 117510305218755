.user-profile-payment-container {
    margin: 20px;
    padding: 20px;
    background: white;
    border-radius: 8px;
}

.user-profile-payment-title {
    font-size: 1.5em;
    margin-bottom: 0px;
    font-weight: bold;
}

.user-profile-payment-content {
    width: 100%;
    margin: 20px auto;
    font-family: Arial, sans-serif;
}

.user-profile-payment-method {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.user-profile-payment-section-title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2px;
}

.user-profile-payment-section-subtitle {
    font-size: 14px;
    color: #666;
    font-weight: 400;
    margin-bottom: 10px;
}

.user-profile-payment-cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 60%;
}

.user-profile-payment-card {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: white;
}

.user-profile-payment-card.selected {
    border: 2px solid #a855f7;
    background-color: #f9f5ff;
}

.user-profile-payment-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border-bottom: none;
}

.user-profile-payment-card-logo {
    width: 40px;
    margin-right: 8px;
}

.user-profile-payment-card-radio {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #a855f7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #a855f7;
}

.user-profile-payment-card.selected .user-profile-payment-card-radio {
    background-color: #a855f7;
    color: white;
}

.user-profile-payment-card-expiry {
    font-size: 14px;
    color: #666;
    padding-left: 3rem;
}

.user-profile-payment-card-actions {
    display: flex;
    font-size: 14px;
    margin-top: 5px;
    gap:20px;
    padding-left: 3rem;
}

.user-profile-payment-set-default {
    color: #666;
    cursor: pointer;
}

.user-profile-payment-edit {
    color: #a855f7;
    cursor: pointer;
}

.user-profile-payment-add-card {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
    text-align: left;
    font-weight: 600;
    color: #475467;
}

/* Scrollable Invoice Section */
.user-profile-payment-history {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin-top: 10px;
}

.user-profile-payment-history::-webkit-scrollbar {
    display: none;
    /* Hides the scrollbar */
}

.user-profile-payment-table {
    width: 100%;
}

.user-profile-payment-table-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    border-bottom: 2px solid #e0e0e0;
    padding: 10px;
    background: #f9f9f9;
    position: sticky;
    top: 0;
}

.user-profile-payment-table-header div:first-child {
    display: flex;
    gap: 10px
}

.user-profile-payment-table-header div:not(:first-child) {
    width: 40%;
    display: flex;
}

.user-profile-payment-table-header div:not(:first-child) span {
    width: 30%;
}

.user-profile-payment-table-header div:not(:first-child) span:last-child {
    width: 10%;
}

.user-profile-payment-table-body {
    display: flex;
    flex-direction: column;
}

.user-profile-payment-table-row {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.user-profile-payment-table-row div:first-child span {
    display: flex;
    gap: 10px
}

.user-profile-payment-table-row div:not(:first-child) {
    width: 40%;
    display: flex;
}

.user-profile-payment-table-row div:not(:first-child) span {
    width: 30%;
}

.user-profile-payment-table-row div:not(:first-child) span:last-child {
    width: 10%;
    text-align: center;
}

.user-profile-payment-status-paid {
    background-color: #d1fadf;
    color: #027a48;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 12px;
}

.user-profile-payment-download-all {
    border: 1px solid #D0D5DD;
    padding: 6px 12px;
    color: #666;
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    font-weight: 600;
}