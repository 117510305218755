/* SubscriptionPlans.css */

/* Container Styling */
.subscription-plans-container {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

/* Title */
.subscription-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* Plans Grid */
.plans-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
  flex-wrap: nowrap;
}

/* Plan Card */
.plan-card {
  background: white;
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 250px;
  text-align: left;
  cursor: pointer;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.plan-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Selected Plan */
.plan-card.selected {
  border-color: #4caf50;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
}

/* Plan Name */
.plan-name {
  font-size: 18px;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

/* Plan Price */
.plan-price {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}

/* Plan Features */
.plan-features {
  list-style: none;
  padding: 0;
  margin: 0;
}

.plan-features li {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

/* Checkout Button */
.checkout-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.checkout-button:hover {
  background-color: #45a049;
}
