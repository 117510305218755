.corporate-explorer-insights-main-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
  width: 100%;
  /* Change from 100vw to 100% */
  margin: 0;
  padding: 0 10px;
  /* Optional: Add padding if you need a slight gap */
}

.Corporate-explore-insight-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* Ensure full width */
}

.corporate-explorer-insights-dropdown-toggle {
  display: flex;
  align-items: center;
  outline: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 16px 60px;
}

.header-heading {
  font-size: 24px;
  font-weight: bold;
}

.corporate-explore-insight-Right-content {
  padding: 20px;
  border-radius: 8px;
}

.corporate-explore-insight-Right-content-data {
  display: flex;
  justify-content: space-between;
  gap: 20;
}

.Corporate-explore-search-insight-title {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-right: -60px;
  cursor: pointer;
}

.Corporate-explore-search-insight-watchlist-main {
  display: flex;
  gap: 3px;
}

.upgrade-button {
  background: #0466d1;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.upgrade-button:disabled {
  color: #8a8a8a;
  background: #cfcfcf;
  cursor: not-allowed;
  border: none;
}

/* side-bar */

.corporate-explore-insight-sidebar {
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 20px;
  /* height: 80vh; */
  overflow-y: auto;
}

.corporate-explore-insight-sidebar::-webkit-scrollbar {
  width: 12px;
}

.corporate-explore-insight-sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.corporate-explore-insight-sidebar::-webkit-scrollbar-thumb {
  background: #d6d5d5;
  border-radius: 10px;
}

.corporate-explore-insight-sidebar::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.corporate-explore-insight-companyLogo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  background: transparent;
}

.corporate-explore-insight-companyName {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -1px;
  color: #000;
  margin-bottom: 5px;
}

.corporate-explore-insight-companyAddress {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

@media (min-width: 300px) and (max-width: 1200px) {
  .corporate-explore-insight-companyLogo,
  .corporate-explore-insight-companyName,
  .corporate-explore-insight-companyAddress {
    display: none;
  }
}

.corporate-explore-insight-menuItem {
  align-items: center;
  padding: 10px 0;
  color: #333;
  cursor: pointer;
  display: block;
  padding-left: 5px;
  border-top: 1px solid #e6e6e6;
  text-decoration: none;
}

.corporate-explore-insight-menuItemIcon {
  margin-right: 10px;
  font-size: 16px;
}

.corporate-explore-insight-menuItem:hover {
  background-color: #eee;
  border-radius: 4px;
}

.corporate-explore-insight-actionBar {
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
  justify-content: space-between;
}

.corporate-explore-insight-actionBarLeft {
  font-size: 18px;
  font-weight: bold;
}

.corporate-explore-insight-actionBarRight {
  display: flex;
  align-items: center;
}

.corporate-explore-insight-actionIcon {
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}

.corporate-explore-insight-actionText {
  margin-left: 5px;
}

.corporate-explore-insight-gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
  height: 80vh;
  overflow: auto;
  /* Allows scrolling when content exceeds container size */
}

/* Webkit browsers */
.corporate-explore-insight-gridContainer::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

.corporate-explore-insight-gridContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the scrollbar track */
}

.corporate-explore-insight-gridContainer::-webkit-scrollbar-thumb {
  background: #cac9c9;
  /* Color of the scrollbar handle */
  border-radius: 10px;
  /* Rounded corners for the scrollbar handle */
}

.corporate-explore-insight-gridContainer::-webkit-scrollbar-thumb:hover {
  background: #cac9c9;
  /* Color of the scrollbar handle on hover */
}

.corporate-explore-insight-gridContainer-forece-mobileview {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  padding: 10px;
}

.corporate-explore-insight-gridItem {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.corporate-explore-insight-gridItem-Appointment {
  background-color: #fff;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  margin-bottom: 10px;
}

.corporate-explore-insight-gridItem-Details {
  background-color: #fff;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  margin-bottom: 10px;
}

.corporate-explore-insight-gridItem-mutual-people {
  background-color: #fff;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 10px;
}

.corporate-explore-insight-watch-list {
  display: flex;
  justify-items: center;
  cursor: pointer;
}

.corporate-explore-insight-watch-list-text {
  color: #fff;
}

.corporate-explore-search-insight-main {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  align-items: center;
  max-height: 50px;
  min-height: 50px;

  position: sticky;
  top: 0;
  z-index: 3;
  background: linear-gradient(99.78deg, #0466d1, #00d1a9 152.3%);
}

@media (min-width: 100px) and (max-width: 1165px) {
  .corporate-explore-insight-gridContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .corporate-explore-insight-gridItem {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 100px) and (max-width: 600px) {
  .corporate-explore-insight-watch-list {
    display: none;
  }

  .upgrade-button {
    display: none;
  }
}

@media (min-width: 300px) and (max-width: 1000px) {
  .Corporate-explore-search-insight-title {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    margin-right: 32px;
    cursor: pointer;
  }

  .corporate-explore-search-insight-main {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    position: sticky;
    top: 0;
    z-index: 3;
    background: linear-gradient(99.78deg, #0466d1, #00d1a9 152.3%);
    padding-left: 8px;
    padding-right: 8px;
    margin-left: auto;
    margin-right: auto;
  }
}
