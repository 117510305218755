.cp-explorer-section {
  padding: 15px;
}

.cp-explorer-filterreset-button-text {
  font-size: 10px;
}

.cp-explorer-filterreset-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 5px;
  width: 60px;
  cursor: pointer;
}

.cp-explorer-filterreset-button:hover {
  background-color: aliceblue;
}

.cp-explorer-section-filter {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
}

.autocomplete {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cp-explorer-section-table {
  margin-top: 40px;
  overflow-x: auto;
}

@media (min-width: 360px) and (max-width: 768px) {
  .cp-explorer-section-table {
    max-width: calc(100vw - 50px); /* Use viewport width for flexibility */
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .cp-explorer-section-table {
    max-width: calc(100vw - 50px); /* Use viewport width for flexibility */
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .cp-explorer-section-table {
    max-width: calc(100vw - 50px); /* Use viewport width for flexibility */
  }
}
