.dashboard-section .main-div .dashboard-content-div .main-card {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 24px;
  background-color: white;
}

.dashboard-section .main-div .dashboard-content-div .main-card .outermost-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

/* .judgment_checker_responsive_image{
      margin-bottom: 289px;

} */
/* .JudgementCheckerPerson-input{
  margin-bottom: 25px;
} */

.JudgementCheckerPerson-continue {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}

@media (min-width: 280px) and (max-width: 430px) {
  .image-res {
    display: none;
  }

  .JudgementCheckerPerson-input {
    width: 100%;
  }

  .parallel-input-div {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .JudgementCheckerPerson-input-postal-code {
    width: 100%;
  }
  .postal-code {
  }
  .JudgementCheckerPerson-continue {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .main-card
  .outermost-div
  .confirm-selection-btn {
  border: 1px solid #0466d1;
  padding: 12px 24px;
  color: #0466d1;
  background-color: white;
  border-radius: 10px;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .main-card
  .cards-outer-div {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 1168px;
  margin-top: 32px;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .main-card
  .cards-outer-div
  .inner-card {
  border: 1px solid #0466d1;
  border-radius: 4px;
  padding: 24px;
  max-width: 280px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .main-card
  .cards-outer-div
  .inner-card.last-card {
  border: 0;
  padding: 0;
  max-width: 280px;
  width: 100%;
  height: 192px;
  display: flex;
  align-items: flex-end;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .main-card
  .cards-outer-div
  .inner-card
  .headingg {
  font-size: 1em;
  font-weight: 600;
  color: #0466d1;
  margin-bottom: 8px;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .main-card
  .cards-outer-div
  .inner-card
  .texxtt {
  font-size: 1em;
  font-weight: 400;
  color: #212529;
}

.modal-header {
  background-color: #0466d1;
}
.modal-title {
  color: #fff;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 150% */
}

.modal-header button.btn-close {
  width: 24px;
  height: 24px;
  background-color: #fff !important;
  border-radius: 50%;
  opacity: 100;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .main-card
  .cards-outer-div
  .inner-card.selected-card {
  color: white !important;
  background: linear-gradient(92.74deg, #0466d1 0%, #00d1a9 100%);
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .main-card
  .cards-outer-div
  .inner-card.selected-card
  .headingg {
  color: white;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .main-card
  .cards-outer-div
  .inner-card.selected-card
  .texxtt {
  color: white;
}

.dashboard-section .main-div .dashboard-content-div .person-detail-card {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 24px 200px 24px 24px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .person-detail-card
  .contennt-div
  .input-outer-div {
  margin-top: 32px;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .person-detail-card
  .img-div {
  max-width: 500px;
  width: 100%;
  height: 100%;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .person-detail-card
  .img-div
  img {
  width: 100%;
  height: 100%;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .person-detail-card
  .contennt-div
  .input-outer-div
  .parallel-input-div {
  display: flex;
  gap: 24px;
  margin-top: 24px;
  align-items: center;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .person-detail-card
  .contennt-div
  .input-outer-div
  .parallel-input-div
  input {
  border: 1px solid #e9e9e9;
  padding: 16px;
  border-radius: 4px;
  outline: none;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .person-detail-card
  .contennt-div
  .input-outer-div
  .parallel-input-div
  input.small-input {
  /* max-width: 150px; */
  width: 100%;
}

.parallel-input-div .find-address-btn {
  border: 1px solid #0466d1;
  /* margin-top: 24px; */
  border-radius: 10px;
  padding: 8px 24px;
  height: 100%;
  cursor: pointer;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .person-detail-card
  .contennt-div
  .input-outer-div
  .parallel-input-div
  .edit-btn {
  color: #0466d1;
  border: 1px solid #0466d1;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 10px;
  background-color: white;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .person-detail-card
  .contennt-div
  .input-outer-div
  .parallel-input-div
  .remove-btn {
  color: #d90350;
  border: 1px solid #d90350;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 10px;
  background-color: white;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .person-detail-card
  .contennt-div
  .input-outer-div
  .parallel-input-div
  .link-text {
  color: #0466d1;
  text-decoration: underline;
  cursor: pointer;
}

.modal-address-body {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

@media (max-width: 1560px) {
  .dashboard-section
    .main-div
    .dashboard-content-div
    .main-card
    .cards-outer-div
    .inner-card {
    max-width: 200px;
    padding: 8px;
  }
}

@media (max-width: 1366px) {
  .dashboard-section .main-div .dashboard-content-div .person-detail-card {
    padding: 24px;
  }
}

@media (max-width: 1280px) {
  .dashboard-section .main-div .dashboard-content-div .person-detail-card {
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .main-card
    .outermost-div {
    flex-direction: column;
  }
}

/* @media (max-width: 768px) {
  .dashboard-section
    .main-div
    .dashboard-content-div
    .main-card
    .cards-outer-div
    .inner-card {
    width: 100%;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .main-card
    .cards-outer-div
    .inner-card.last-card {
    height: fit-content;
    margin-bottom: 32px;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .main-card
    .cards-outer-div
    .inner-card.last-card
    img {
    width: 100%;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .main-card
    .cards-outer-div {
    max-width: 98%;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .person-detail-card
    .contennt-div
    .input-outer-div
    .parallel-input-div {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .person-detail-card
    .contennt-div
    .input-outer-div
    .parallel-input-div
    .find-address-btn {
    padding: 4px 12px;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .person-detail-card
    .contennt-div
    .input-outer-div
    .parallel-input-div
    input {
    padding: 4px 8px;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .first-parallel-div
    .cards-div
    .btn-div
    button,
  .dashboard-section
    .main-div
    .dashboard-content-div
    .main-card
    .outermost-div
    .confirm-selection-btn {
    padding: 4px 8px;
  }
} */

.error {
  color: red;
}
