/* Container styles */
.post-auth-flow-container-main {
  text-align: center;
  max-width: 100%;
  /* padding: 20px; */
  display: block;
  justify-content: center;
  align-items: center;
 
}

.post-auth-flow-container-main-image {
  background-image: url(https://www.probedigital.co.uk/hubfs/Frame%201.png);
  background-size: cover;
  background-position: right bottom;
  margin-bottom: 20px;
  height: 40vh;
  position: relative;
  /* border-radius: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center;
  text-align: center; */
}
.post-auth-flow-container-main-image{
  background-color: rgba(0, 0, 0, 40%);
    bottom: 0;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
}
.post-auth-flow-container-main-heading {
  color: #fff;
  font-weight: 600;
    font-size: 3em;
    line-height: 1.5em;
  text-transform: none;
  margin: 0;
}

.post-auth-flow-container-main-subheading {
  color: #fff;
  font-weight: 600;
    font-size: 3em;
    line-height: 1.5em;
  text-transform: none;
  margin-top: 10px; /* Small gap between heading and subheading */
}



/* Card container styles */
.post-auth-flow-container-card-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; 
}

/* Styles for cards */
.post-auth-flow-container-judgement-card,
.post-auth-flow-corporate-explorer-card {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
  text-align: center;
  width: 40%;
  height: auto;
  background: rgba(251, 251, 251, 1);
  border-radius: 16px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  margin-bottom: 50px;
}

.post-auth-flow-container-card-image {
  width: 95%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;
  transition: transform 0.3s ease;
}

.post-auth-flow-container-card-title,
.post-auth-flow-container-card-description
 {
  transition: color 0.3s ease;
  
}

.post-auth-flow-container-card-link {
  list-style: none;
    margin: 0;
    padding-left: 0;
  
}
.post-auth-flow-container-card-title {
    color: #0466d1;
    font-family: Inter, Arial;
    font-style: normal;
    font-weight: 600;
    letter-spacing: .5px;
}

.post-auth-flow-container-card-description {
     color: #53535b;
    font-weight: 400;
    letter-spacing: .1px;
    line-height: 26px;
    font-size: 16px;
    font-style: normal;
}

.post-auth-flow-container-card-link {
  color: #1a73e8;
  font-size: 14px;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  margin-top: 15px;
}

.post-auth-flow-container-card-link::before {
  content: '/// ';
  color: #1a73e8;
  font-weight: bold;
}

.post-auth-flow-container-judgement-card:hover,
.post-auth-flow-corporate-explorer-card:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.post-auth-flow-container-judgement-card:hover .post-auth-flow-container-card-image,
.post-auth-flow-corporate-explorer-card:hover .post-auth-flow-container-card-image {
  transform: scale(1.1);
}

.post-auth-flow-container-judgement-card:hover .post-auth-flow-container-card-title,
.post-auth-flow-container-judgement-card:hover 
.post-auth-flow-container-judgement-card:hover .post-auth-flow-container-card-link,
.post-auth-flow-corporate-explorer-card:hover .post-auth-flow-container-card-title,
.post-auth-flow-corporate-explorer-card:hover 
.post-auth-flow-corporate-explorer-card:hover .post-auth-flow-container-card-link {
  color: #0b56c4; 
}

/* Card link styles */
.post-auth-flow-container-card-link {
  color: #28a745;
  text-decoration: none;
}



/* Responsive Styles for min-width: 360px and max-width: 992px */
@media (min-width: 100px) and (max-width: 992px) {
  .post-auth-flow-container-card-container {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center align cards */
  }

  .post-auth-flow-container-judgement-card,
  .post-auth-flow-corporate-explorer-card {
    width: 90%; /* Adjust width for mobile view */
  }


  
}
