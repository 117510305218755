.tiles-x {
  display: flex;
  justify-content: flex-end;
}

.title {
  font-size: 1.5em;
  color: #021B41;

}

.count {
  background-color: #ff6f61;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  margin-left: 10px;
}

.upgrade-notice {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.upgrade-box {
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
}

.icon {
  background-image: url("../../../assets/images/social/double-user.png");
  width: 50px;
  height: 50px;
  background-size: cover;
  margin: 0 auto 20px auto;
}

.message {
  max-width: 400px;
  margin: 0 auto;
}

.message strong {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.plans-button {
  background: linear-gradient(99.78deg, #0466d1 0%, #00d1a9 152.3%);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
}

.plans-button:disabled {
  color: #8a8a8a;
  background: #CFCFCF;
  cursor: not-allowed;
  border: none;
}