.servicesTable-info-section {
  margin-top: 24px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}

.servicesTable-info-section .header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: white;
  color: black;
}

.servicesTable-info-section .table-custom thead {
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.5em;
  color: #656565;
}

.servicesTable-info-section .table-custom .thead-td-custom {
  display: flex;
  position: relative;
}

.servicesTable-info-section .table-custom thead tr td {
  border-bottom-width: 0px;
}

.servicesTable-info-section .table-custom thead .img-thead {
  margin-right: 6px;
  margin-bottom: 2px;
}

.servicesTable-info-section .table-custom td {
  padding: 12px 24px;
}

.small-sub-text {
  font-size: 0.5em;
  position: absolute;
  bottom: -12px;
  left: 24px;
  font-weight: 600;
}

.servicesTable-info-section .table-custom .yes-tag {
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.5em;
  color: #212529;
  background: rgba(0, 209, 109, 0.05);
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
  border-radius: 6px;
}

.servicesTable-info-section .table-custom .no-tag {
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.5em;
  color: #212529;
  background: rgba(219, 1, 40, 0.05);
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
  border-radius: 6px;
}

.heading-table {
  padding: 24px;
}

@media (max-width: 768.99px) {
  .servicesTable-info-section .table-custom td {
    padding: 12px 12px;
    /* font-size: 14px; */
    /* line-height: 18px; */
    text-align: center;
  }

  .table-responsive {
    max-width: 620px;
    width: 100%;
  }
}
