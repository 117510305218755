.judgement_check_terms_conditions_policy {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
  margin-top: 5vh;
  gap: 20px;
  letter-spacing: 1.1px;
  margin-bottom: 20px;
}

.judgement_check_terms_conditions_policy_main_heading {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.judgement_check_terms_conditions_policy_bold_text {
  font-weight: bold;
  font-size: large;
}

.judgement_check_terms_conditions_policy_normal_text {
  font-size: medium;
}

@media (max-width: 402px) or (max-width: 430px) {
  .judgement_check_terms_conditions_policy {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 5vh;
    gap: 20px;
    margin-bottom: 20px;
  }

  .judgement_check_terms_conditions_policy_hyper_links {
    color: blue;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }

  .judgement_check_terms_conditions_policy_main_heading {
    font-size: 15px;
    margin-top: 1px;
    margin-bottom: 1px;
    font-weight: bold;
  }

  .judgement_check_terms_conditions_policy_bold_text {
    font-weight: bold;
    font-size: small;
  }

  .judgement_check_terms_conditions_policy_normal_text {
    font-size: small;
  }
}

@media (max-width: 768px) or (max-width: 1024px) {
  .judgement_check_terms_conditions_policy {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 5vh;
    gap: 20px;
    margin-bottom: 20px;
  }
}
