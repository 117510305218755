.cei-latest-activity h3 {
  font-size: 1.25rem;
  color: #021B41;
  z-index: -1;
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
}

.activity-list-container {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
  position: relative;
}

.activity-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.activity-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 15px;
  padding-left: 30px;
}

.activity-item::before {
  content: "";
  position: absolute;
  left: 13px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #ddd;
}

.activity-icon {
  font-size: 1rem;
  margin-right: 10px;
  position: relative;
  z-index: 1;
}

.activity-details {
  flex: 1;
}

.activity-type {
  margin: 0;
  font-weight: bold;
}

.person-name {
  color: #007bff;
  text-decoration: none;
}

.activity-time {
  color: #666;
  margin: 0;
}

.activity-date {
  margin-left: 5px;
  color: #999;
}

.add-to-watch-list-btn {
  background: #eff0f2;
  color: #444548;
  cursor: pointer;
  padding: 4px 12px;
  text-decoration: none !important;
  white-space: nowrap;
  font-size: 9pt;
  border-radius: 3px;
  text-align: center;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.21);
  font-weight: 700 !important;
  border: 0;
  border-radius: 50px !important;
}

.add-to-watch-list-btn:disabled {
  color: #8a8a8a;
  background: #CFCFCF;
  cursor: not-allowed;
  border: none;
}

/* Custom scrollbar styling */
.activity-list-container::-webkit-scrollbar {
  width: 8px;
}

.activity-list-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.activity-list-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.activity-list-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}