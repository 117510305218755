.quickview-content {
  background: #fff;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.43);
  border-radius: 5px;
  width: 300px;
  height: auto;
  animation-name: scale;
  animation-duration: 270ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.quickview-header {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.building-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../../assets/images/domain.png') no-repeat center center;
  background-size: contain;
  margin-right: 5px;
  margin-bottom: 5px;
}

.company-name {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  /* width: 240px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.address {
  font-size: 11px;
  color: #666;
  margin-right: 30px;
}

.items {
  padding: 8px;
  font-size: 11px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.status-green {
  display: inline-block;
  background: rgb(22, 160, 134);
  color: #fff;
  border-radius: 3px;
  font-weight: bold;
  margin-right: 5px;
}

.company-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url('../../assets/images/company-icon.png') no-repeat center
    center;
  background-size: contain;
  margin-right: 5px;
  margin-bottom: 5px;
}

.type-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url('../../assets/images/company-pvt.png') no-repeat center center;
  background-size: contain;
  margin-right: 5px;
  margin-bottom: 5px;
}

.date-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../../assets/images/calender.png') no-repeat center center;

  background-size: contain;
  margin-right: 5px;
  margin-bottom: 5px;
}

.persons-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../assets/images/user.png') no-repeat center center;
  background-size: contain;
  margin-right: 5px;
}

.status-green {
  display: inline-block;
  background-color: rgb(22, 160, 134);
  color: #fff;
  padding: 0px 6px;
  border-radius: 3px;
  font-weight: bold;
  margin-right: 5px;
}

.finance {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #ddd;
  font-size: 12px;
}

.t3 {
  font-size: 12px;
  color: #555;
  margin: 0;
  padding: 0;
  gap: 0;
}

.t1 {
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin-top: 4px;
  padding-bottom: 16px;
}
.t3,
.t1 {
  line-height: 0;
}
.finance-total {
  gap: 0;
  margin: 0;
  padding: 0;
}
