.corporate-insights-result-card {
    background-color: white;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 124px;
    border: 2px solid white;
}

.CompanyItem-selected-card {
    border: 2px solid rgb(2, 156, 189) !important;
}

.corporate-insights-result-card-content-header p {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #333;
    margin: 0;
}

.corporate-insights-result-card-body {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.corporate-insights-result-card-info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.corporate-insights-result-card-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.corporate-insights-result-card-info {
    font-size: 10px;
    font-weight: 400;
    line-height: 1.8;
    color: #3D3D3D;
}

.corporate-insights-result-card-value {
    flex-grow: 1;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: #3D3D3D;
    text-align: left;
}

.corporate-insights-result-card-status-active {
    background: linear-gradient(to bottom right, #0466D1, #00D1A9);
    color: white;
    border-radius: 3px;
    font-size: 0.875rem;
    font-style: italic;
}

.corporate-insights-result-card-status-inactive {
    color: white;
    border-radius: 3px;
    font-size: 0.875rem;
    font-style: italic;
}

.corporate-insights-result-card-avatar {
    width: 60px;
    height: 60px;
    background: linear-gradient(to bottom right, #0466D1, #00D1A9);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.corporate-insights-result-card-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.corporate-insights-result-card-header-left {
    display: flex;
    align-items: center;
}

.corporate-insights-result-card-header-name {
    font-size: 12px;
    font-weight: 600;
    color: #333;
}

.corporate-insights-result-card-header-flag {
    margin-left: 20px;
}

.corporate-insights-result-card-flag-icon {
    width: 15px;
    height: 10px;
}

.corporate-insights-result-card-header-right {
    display: flex;
    align-items: center;
}

.result-link {
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
}

.result-link:hover {
    text-decoration: underline;
}

@media (min-width: 200px) and (max-width: 992px) {
    .corporate-insights-result-card {
        width: 100%;
        padding: 15px;
    }

    .corporate-insights-result-card-info-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .corporate-insights-result-card-label {
        margin-bottom: 5px;
    }
}