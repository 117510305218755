.searchresult-info-section {
  margin-top: 24px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}

.searchresult-info-section .header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: white;
  color: black;
}

.searchresult-info-section .table-custom thead {
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1em;
  color: #656565;
}

.searchresult-info-section .table-custom .thead-td-custom {
  display: flex;
}

.searchresult-info-section .table-custom thead tr td {
  border-bottom-width: 0px;
}

.searchresult-info-section .table-custom thead .img-thead {
  margin-right: 6px;
  margin-bottom: 2px;
}

.searchresult-info-section .table-custom td {
  padding: 12px 24px;
}

.heading-table {
  padding: 24px;
}

@media (max-width: 768.99px) {
  .searchresult-info-section .table-custom td {
    padding: 12px 12px;
    /* font-size: 14px;
         line-height: 18px; */
    text-align: center;
  }

  .table-responsive {
    max-width: 600px;
    width: 100%;
  }
}
