.corporate-links-Graph-sidebar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 25px;
  cursor: pointer;
  height: 44px;
  min-width: 40px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0 10px;
}

.corporate-links-Graph-sidebar-item:hover {
  color: rgb(2, 156, 189);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
}

.corporate-links-Graph-sidebar-item.active {
  background-color: #0380C7;
  color: white;
}

.corporate-links-Graph-sidebar-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
}

.corporate-links-Graph-sidebar-item-icon-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: white;
  color: black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  font-size: 18px;
}

.corporate-links-Graph-sidebar-item-disable {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.corporate-links-Graph-sidebar-item-description {
  display: none;
  flex: 1;
  word-wrap: break-word;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.corporate-links-Graph-sidebar-item:hover .corporate-links-Graph-sidebar-item-description,
.corporate-links-Graph-sidebar-item.active .corporate-links-Graph-sidebar-item-description {
  display: block;
}