.corporate-links-graph-mini-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 5px solid ghostwhite;
}

.corporate-links-graph-mini-header-bdn {
  display: flex;
  gap: 10px;
}

.corporate-links-graph-main-sidebar {
  display: flex;
  justify-content: space-between;
  height: 88vh;
  z-index: -100;
}

.right-burger-menu {
  font-size: 20px;
  cursor: pointer;
}

.corporate-links-graph-main-content {
  display: flex;
}
