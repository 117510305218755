.graph-Right-side-bar-menu-container {
  position: relative;
}

.graph-Right-side-bar-btn-group {
  display: flex;
  gap: 10px;
  width: 100%;
}

.graph-Right-side-bar-btn-group>*:not(:last-child) {
  margin-right: 10px;
}

/* Burger Menu Styles */
.graph-Right-side-bar-burger-menu {
  position: 'absolute';
  right: 1;
  display: 'flex';
  align-items: 'center';
  cursor: pointer;
  display: none;
  font-size: 24px;
  background: none;
  border: none;
}

.graph-right-sidebar {
  position: fixed;
  top: 0;
  right: -360px;
  width: 360px;
  height: 100%;
  background-color: rgb(243, 243, 243);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out;
  padding: 20px;
  z-index: 1000;
}

.graph-right-sidebar.open {
  right: 0;
}

.graph-Right-side-bar-menu-close-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  font-size: 24px;
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  color: rgb(2, 156, 189);
}

.graph-Right-side-bar-menu-items {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.graph-Right-side-bar-menu-items li {
  margin-bottom: 20px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.graph-Right-side-bar-menu-items li svg {
  margin-right: 10px;
}

/* Responsive styles for mobile view */
@media (max-width: 900px) {
  .graph-Right-side-bar-btn-group {
    display: none;
  }

  .graph-Right-side-bar-burger-menu {
    display: block;
    right: 1;
  }
}

.graph-Right-side-bar-graph-btns-plus-minus {
  display: flex;
  border: 1px solid #D0D5DD;
  border-radius: 5px;
  height: 50px;
  min-width: 298px;
  justify-content: space-between;
  align-items: center;
  padding: 2px 5px;
}

@media (min-width: 530px) and (max-width: 992px) {
  .graph-Right-side-bar-graph-btns-plus-minus {
    display: none;
  }

  .graph-Right-side-bar-btn-group {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 530px) {
  .graph-Right-side-bar-graph-btns-plus-minus {
    display: none;
  }

  .graph-Right-side-bar-menu-container {
    width: 80%;
  }

  .graph-Right-side-bar-btn-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}