/* Signup.css */

.probe-signup-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 10px;
  border-radius: 8px;
  margin: 0 auto;
  width: 80%;
}

.probe-signup-title {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  margin-top: 15px;
  margin: 8px 0;
}

.probe-signup-input {
  width: 100%;
  border-radius: 6px;
  padding: 4px 6px;
  border: 1px solid #D0D5DD;
  font-size: 13px;
}

/* Google Button */
.google-auth-button {
  width: 100%;
  border: 1px solid #D0D5DD;
  padding: 4px 6px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.probe-signup-error{
  font-weight: 300;
  font-size: 12px;
  margin-top: 1px;
  margin-bottom: 1px;
  color: red;
}