

@media (min-width: 100px) and (max-width: 992px) {
  .corporate-explore-insight-container-logo {
    display: flex; /* Display when within the specified range */
    justify-content: flex-start;
    align-items: center;
  }
}

.corporate-explore-insight-company-Logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.company-logo {
  width: 80px;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px; /* Space between logo and company name */
}

.corporate-explore-insight-company-Name {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -1px;
  color: #000;
}

.corporate-explore-insight-company-Address {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}
