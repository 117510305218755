.corporate-explore-insight-appointments-container {
  background-color: transparent;
  border: none;
  width: 90%;
}

.corporate-explore-insight-appointments-title {
  font-size: 16px;
  font-weight: 600;
}

.corporate-explore-insight-appointments-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.corporate-explore-insight-appointments-stat {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.corporate-explore-insight-appointments-stats span {
  display: block;
  font-size: 14px;
  color: #555;
  margin-top: 4px;
}

.corporate-explore-insight-appointments-stats h2 {
  margin-top: 8px;
  margin-bottom: 4px;
  margin-left: -4px;
  font-size: 24px;
  font-weight: 700;
  color: #333;
}

.corporate-explore-insight-appointments-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 8px;
}

.corporate-explore-insight-appointments-dot.active {
  background-color: #4caf50;
}

.corporate-explore-insight-appointments-dot.resigned {
  background-color: #f44336;
}

.corporate-explore-insight-appointments-dot.dissolved {
  background-color: #d32f2f;
}

.corporate-explore-insight-appointments-dot.other {
  background-color: #9e9e9e;
}

.corporate-explore-insight-appointments-dot.total {
  background-color: #bdbdbd;
}
