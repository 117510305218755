.corporate-links-graph-right-sidebar-mian {
  overflow-y: auto;
  max-height: 100vh;
  width: 600px;
  /* margin-top:60px; */
}

.corporate-links-graph-right-sidebar {
  position: fixed;
  right: -600px; /* Initially hidden */
  height: 100%;
  background-color: ghostwhite;
  transition: right 0.3s ease;
  cursor: pointer;
  z-index: 99999; /* Increased z-index to ensure it's on top */
  top: 1px; /* Ensures it starts from the top */
}

/* .corporate-links-graph-right-sidebar-mian-cross {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  
} */
.corporate-links-graph-right-sidebar-mian-cross {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white; /* Optional: Ensures background color is maintained */
  border-bottom: 1px solid #ddd; /* Optional: Adds a border to separate the sticky area */
  
  position: sticky; /* Makes it sticky */
  top: 0; /* Sticks to the top */
  z-index: 10; /* Ensures it stays above other content in the sidebar */
}
.corporate-links-graph-right-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.corporate-links-graph-right-sidebar-close-btn {
  color: dodgerblue;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.corporate-links-graph-right-sidebar-close-btn:hover {
  color: dodgerblue;
  background-color: whitesmoke;
}

.corporate-links-graph-right-sidebar-heading {
  font-size: 18px;
  font-weight: bold;
}

.corporate-links-graph-right-sidebar-graph-heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.corporate-links-graph-right-sidebar-form-group {
  margin-bottom: 15px;
}

.corporate-links-graph-right-sidebar-form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.corporate-links-graph-right-sidebar-apply-filters-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #0078d7;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.corporate-links-graph-right-sidebar-apply-filters-btn:hover {
  background-color: #0078d7; /* Hover color */
}

.corporate-links-graph-right-sidebar.open {
  right: 0; /* When open */
  margin-top: 75px;
}

@media (min-width: 200px) and (max-width: 344px) {
  .corporate-links-graph-right-sidebar-mian {
    width: 200px;
  }

  .corporate-links-graph-right-sidebar {
    right: -200px;
  }
}

@media (min-width: 344px) and (max-width: 400px) {
  .corporate-links-graph-right-sidebar-mian {
    width: 350px;
  }

  .corporate-links-graph-right-sidebar {
    right: -350px;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .corporate-links-graph-right-sidebar-mian {
    width: 400px;
  }

  .corporate-links-graph-right-sidebar {
    right: -400px;
  }
}
