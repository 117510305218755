.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
  background-image: linear-gradient(to bottom right, #0466d1cc, #00d1a9cc),
    url('https://s3-alpha-sig.figma.com/img/07a7/42ba/455ab0e466c8aa901688bec6f2475a67?Expires=1740355200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Uz5qwUTZ1C-23wwXLGbl5alYhEaJDg0kXwiqBlyEz64HtUy-mRH5uutyvtwphtCsxTqE60Hw1~jPjuJNKjeQd7pL5WI~wNkjJC12992u95trF1M9lB~qa8ym8SXUJGFebjfvdohgoAAj9mpgTW6pJsAg5ay3E~T23U4ixLsJEw3GGAMK3g7Re5XBjz41wGA3r72NovZcWVaHzkvnTegIRCszObjBfcMdaUw2DJvDAGARCbwM90GEUAuF7wOPowTIBIeAAJ7pHxV2CbwTMF8Gv6B8M-ZPjd~UZvCz8dPPjp6OoA6gfeWCHZkW~SEpQHcpfgKhiEa2OHBSuilCVZWPBw__');
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;
  padding: 20px;
}

.pricing-plan-selected {
  background-color: rgba(0, 209, 169, 0.5) !important;
}

.banner-heading {
  font-size: 55px;
  margin: 0;
}

.banner-paragraph {
  font-size: 16px;
  margin: 0;
  max-width: 100%;
}

.button-container {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.banner-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #00d1a9;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  width: 246px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.5);
}

.services {
  text-align: center;
  padding: 20px 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-heading {
  max-width: 80%;
  text-align: center;
  margin: 2% 0;
}

.services span {
  color: #0466d1;
  padding: 3px 8px;
  border: 1px solid #0466d1;
  border-radius: 20px;
}

.services h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 12px 0;
}

.services p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.services-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 20px;
}

.service-box {
  background-color: #fff;
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  flex: 1 1 calc(50% - 20px);
  /* Two boxes per row */
  max-width: calc(50% - 20px);
  padding: 20px 5%;
  cursor: pointer;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.service-box-icon {
  margin-bottom: 15px;
}

.service-box h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.service-box p {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.service-box span {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.service-box a:hover {
  text-decoration: underline;
}

.service-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.pricing {
  text-align: center;
  padding: 20px 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-heading {
  max-width: 80%;
  text-align: center;
  margin: 2% 0;
}

.pricing span {
  color: #0466d1;
  padding: 3px 8px;
  border: 1px solid #0466d1;
  border-radius: 20px;
}

.pricing h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 12px 0;
}

.pricing p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.pricing-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px;
  width: 100%;
}

.pricing-box {
  flex: 1 1 calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.pricing-box h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.pricing-box .price {
  font-size: 20px;
  margin: 10px 0;
  color: #444;
}

.pricing-box .price span {
  font-size: 14px;
  color: #666;
}

.pricing-box ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  text-align: left;
}

.pricing-box ul li {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.pricing-box .btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
}

.footer {
  background-color: #121212;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* Align items at the start of the row */
  margin: 0 10%;
}

.footer-left,
.footer-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.footer-left h3,
.footer-right h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-left h3 {
  font-size: 20px;
  color: #0466d1;
  margin-bottom: 20px;
}

.footer-left p {
  margin: 0;
  line-height: 1.5;
  flex: 1;
}

.footer-social-icons img {
  width: 15px;
  height: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.footer-right p {
  margin: 5px 0;
  line-height: 1.5;
  display: flex;
}

.footer-right span {
  margin-right: 10px;
}

.footer-bottom {
  border-top: 1px solid #333;
  margin-top: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.footer-bottom p {
  margin: 0 10%;
}

.footer-bottom a {
  color: #fff;
  text-decoration: none;
  margin: 0 5px;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-left {
  flex: 1;
  /* Allow this div to take up as much space as possible */
}

.footer-right {
  flex: 0;
  padding-right: 3%;
}

@media (max-width: 768px) {
  .pricing-box {
    flex: 1 1 calc(50% - 20px);
    /* Two boxes per row */
    max-width: calc(50% - 20px);
  }

  .banner {
    height: auto;
    /* Flexible height for smaller screens */
  }

  .button-container {
    flex-direction: column;
  }

  .banner-button {
    width: auto;
  }
}

@media (max-width: 480px) {
  .pricing-box {
    flex: 1 1 100%;
    /* One box per row */
    max-width: 100%;
  }
}