.corporate-explore-insight-person-header-card {
  display: flex;
  align-items: center;
    border: 1px solid rgb(223, 222, 222);
  padding: 40px;
  border-radius: 20px;
  /* background-color: whitesmoke; */
  /* max-width: 400px; */
  width: 100%;
  margin: 0 auto;
  margin-bottom: 5px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.corporate-explore-insight-person-profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
  margin-bottom: 10px; /* Adds spacing when wrapped */
}

.corporate-explore-insight-person-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.corporate-explore-insight-person-name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.corporate-explore-insight-person-title,
.corporate-explore-insight-person-location {
  font-size: 14px;
  color: gray;
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .corporate-explore-insight-person-header-card  {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start; /* Align content to the start */
    padding: 15px; /* Increase padding for better touch targets */
  }

  .corporate-explore-insight-person-profile-image {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .corporate-explore-insight-person-name {
    font-size: 16px;
  }

  .corporate-explore-insight-person-title,
  .corporate-explore-insight-person-location {
    font-size: 13px;
  }
}
