.first-parallel-div .edit-remove-btn-div {
  position: absolute;
  top: 104px;
  right: 24px;
  display: flex;
  gap: 24px;
}

.first-parallel-div .edit-remove-btn-div .edit-btn {
  color: #0466d1;
  border: 1px solid #0466d1;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 10px;
  background-color: white;
}

.first-parallel-div .edit-remove-btn-div .remove-btn {
  color: #d90350;
  border: 1px solid #d90350;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 10px;
  background-color: white;
}

@media (max-width: 1280px) {
  .first-parallel-div .edit-remove-btn-div {
    position: relative;
    margin: 0 24px 16px;
    top: unset;
    right: unset;
  }
}

@media (max-width: 1024px) {
  .dashboard-section
    .main-div
    .dashboard-content-div
    .first-parallel-div
    .cards-div.card-6 {
    max-width: 100%;
  }

  .first-parallel-div .edit-remove-btn-div .edit-btn,
  .first-parallel-div .edit-remove-btn-div .remove-btn {
    padding: 4px 8px;
  }
}

.card-headers-button-text {
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #e9e9e9;
  padding: 12px 0px 12px 36px;
  color: #021b41;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}
