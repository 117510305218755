.accounts-profile-details-section {
  display: flex;
  align-items: flex-start;
  gap: 70px;
  background-color: white;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 24px;
}

.accounts-profile-details-section .outer-div div {
  margin-top: 8px;
  margin-bottom: 8px;
}

.accounts-profile-details-section .outer-div.custom-margin {
  margin-left: 10px;
  flex: 1;
}

.accounts-profile-details-section .outer-div.custom-margin .profile-div {
  display: flex;
  width: 100%;
}

.accounts-profile-details-section
  .outer-div.custom-margin
  .profile-div
  .label-text {
  max-width: 60px;
  flex: 1;
}

.accounts-profile-details-section .outer-div.round-img-div {
  max-width: 156px;
  width: 100%;
  height: 156px;
  background-color: #f5f5f5;
  border-radius: 50%;
  align-self: center;
}

.label-text {
  font-weight: 500;
  font-size: 0.875em;
  line-height: 18px;
  color: #656565;
  margin-right: 16px;
}

@media (max-width: 768.99px) {
  .accounts-profile-details-section {
    min-width: 620px;
    flex-direction: column;
    gap: 25px;
  }

  .accounts-profile-details-section .outer-div.round-img-div {
    align-self: flex-start;
  }

  .accounts-profile-details-section .outer-div.custom-margin {
    margin-left: 0px;
  }
}

/* Default styles for larger screens */
.outer-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Media query for screens with a maximum width of 768px */
@media (max-width: 768px) {
  /* Adjust the styling for smaller screens here */
  .label-text {
    width: 200px;
    justify-content: flex-start;
  }
  .outer-div {
    flex-direction: column;
    align-items: center;
  }

  /* Optionally, you can add additional styles for better spacing on smaller screens */
  .profile-div {
    margin-bottom: 10px; /* Adjust the margin as needed */
  }
}
