.pricing-page-main-margin-top {
  margin-top: 10%;
}

.pricing-page-main {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  background: linear-gradient(92.74deg, #0466d1 0%, #00d1a9 100%);
  min-height: 100vh; /* Make sure it covers full height */
  padding: 0; /* Reset any padding */
  margin: 0; /* Reset any margin */
}

.pricing-page-main-judgement-checker-main {
  background-color: #ffffff;
  padding: 50px;
  border: 5px solid rgb(130, 158, 230);
  border-radius: 15px;
  width: 70%;
  margin-top: 2%;
}

.pricing-page-main-judgement-checker-main-header {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.pricing-page-main-judgement-checker-main-feature {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.pricing-page-main-judgement-checker-main-content {
  display: flex;
  margin-bottom: 5px;
}

.pricing-page-main-judgement-basic-pro-enterprise {
  display: flex;
  gap: 20px;
  width: 70%;
  margin-bottom: 2%;
}

.priceTag {
  background-color: #ffffff; /* Light blue background */
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Simple box-shadow for depth */
}

.originalPrice {
  text-decoration: line-through;
  color: #6c757d; /* Assuming a grey color for the original price */
  margin-bottom: 5px; /* Space between the original price and the sale price */
}

.salePrice {
  font-size: 24px; /* Larger font size for the sale price */
  color: #00d1a9;
  font-weight: bold; /* Make the sale price bold */
}

@media (min-width: 900px) {
  .pricing-page-main {
    background: linear-gradient(92.74deg, #0466d1 0%, #00d1a9 100%);
    display: flex;
    gap: 20px;
    flex: 1;
  }

  .pricing-page-main-margin-top {
    margin-top: 10%;
  }

  .pricing-page-main-judgement-checker-main {
    padding: 20px;
    width: 60%;
    border: 1px solid rgb(130, 158, 230);
  }

  .pricing-page-main-judgement-checker-main-header,
  .pricing-page-main-judgement-checker-main-feature {
    font-size: 14px;
  }

  .pricing-page-main-judgement-basic-pro-enterprise {
    align-items: stretch;
    width: 60%;
    gap: 10px;
  }

  .priceTag {
    width: 100px;
    height: 100px;
  }

  .originalPrice {
    font-size: 12px;
  }

  .salePrice {
    font-size: 20px;
    color: #00d1a9;
  }
}

@media (max-width: 900px) {
  .pricing-page-main {
    background: linear-gradient(92.74deg, #0466d1 0%, #00d1a9 100%);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    flex: 1;
  }

  .pricing-page-main-margin-top {
    margin-top: 2%;
  }

  .pricing-page-main-judgement-checker-main {
    padding: 20px;
    width: 95%;
    border: 1px solid rgb(130, 158, 230);
  }

  .pricing-page-main-judgement-checker-main-header,
  .pricing-page-main-judgement-checker-main-feature {
    font-size: 14px;
  }

  .pricing-page-main-judgement-basic-pro-enterprise {
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }

  .priceTag {
    width: 80px;
    height: 80px;
  }

  .originalPrice {
    font-size: 12px;
  }

  .salePrice {
    font-size: 20px;
    color: #00d1a9;
  }
}
