.probeInput {
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-transform: uppercase;
}

.probeInput:focus {
  border-color: #0466d1;
  outline: none;
}

.probe_input_use_form_error_main_div {
  min-height: 30px;
  margin: 2px;
  width: 100%;
}

.probe_input_use_form_error_place_holder {
  min-height: 30px;
  margin: 2px;
}

@media (max-width: 1200px) {
  .probe_input_use_form_error_main_div {
    min-height: 12px;
    margin: 2px;
    width: 100%;
  }

  .probe_input_use_form_error_place_holder {
    min-height: 12px;
    margin: 2px;
    width: 100%;
  }
}
