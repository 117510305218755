/* .sidebar-outer {
  display: flex;
}

main {
  width: 100%;
  background-color: #f5f5f5;
}

.sidebar {
  background: linear-gradient(180deg, #0466d1 7.81%, #00d1a9 117.33%);
  color: white;
  min-height: 100vh;
  height: 100%;
  width: 280px;
  transition: all 0.2s ease-in-out;
  padding: 24px 8px;
  position: sticky;
  top: 0;
  bottom: 0;
}

.sidebar-opened {
  width: 288px;
}

.sidebar-closed {
  width: 96px;
}

.top_section {
  display: flex;
  align-items: center;
  padding-bottom: 56px;
  justify-content: center;
}

.logo {
  font-size: 25px;
}

.menu-btn {
  border: none;
  background-color: transparent;
  outline: none;
  position: absolute;
  top: 22px;
  right: 0px;
}

.link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 10px 10px;
  gap: 15px;
  transition: all 0.5s ease-in-out;
  margin-bottom: 20px;
  font-size: 0.75em;
}

.link:hover {
  color: white;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  justify-content: flex-start;
}

.sidebar-icon,
.link_text {
  text-decoration: none;
}

.sidebar-burger-btn {
  display: none;
}

@media (max-width: 768.99px) {
 

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar.show {
    transform: translateX(0);
  }

  .sidebar.hide {
    transform: translateX(-100%);
  }

  .link {
    justify-content: center;
  }

  .sidebar-closed {
    width: 74px;
  }
}

@media (max-width: 575.99px) {
  main {
    width: 100%;
  }
} */




/* overlay sidebar css */
.sidebar-outer {
  display: flex;
}

main {
  width: 100%;
  background-color: #f5f5f5;
}

.sidebar {
  background: linear-gradient(180deg, #0466d1 7.81%, #00d1a9 117.33%);
  color: white;
  min-height: 100vh;
  height: 100%;
  width: 280px;
  transition: all 0.2s ease-in-out;
  padding: 24px 8px;
  position: fixed;
  top: 0;
  bottom: 0;
  transform: translateX(-100%);
  z-index: 2000;

}

.sidebar.show {
  transform: translateX(0);
}

.sidebar.hide {
  transform: translateX(-100%);
}

.top_section {
  display: flex;
  align-items: center;
  padding-bottom: 56px;
  justify-content: center;
}

.logo {
  font-size: 25px;
}

.menu-btn {
  border: none;
  background-color: transparent;
  outline: none;
  position: absolute;
  top: 22px;
  right: 0px;
}

.link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 10px 10px;
  gap: 15px;
  transition: all 0.5s ease-in-out;
  margin-bottom: 20px;
  font-size: 0.75em;
}

.link:hover {
  color: white;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  justify-content: flex-start;
}

.sidebar-icon,
.link_text {
  text-decoration: none;
}

.sidebar-burger-btn {
  display: block;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1001;
  position: fixed;
  top: 20px;
  left: 20px;
}

@media (max-width: 768.99px) {
  .link {
    justify-content: center;
  }
}

@media (max-width: 575.99px) {
  main {
    width: 100%;
  }
}
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
  color: #ffffff; /* Change color as needed */
  z-index: 1; /* Make sure it's above other content */
}

.close-icon:hover {
  color: #000; /* Darken color on hover */
}