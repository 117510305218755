.cl-graph-total-dropdown-container {
  position: relative;
  display: inline-block;
  width: 200px; /* Adjust width as needed */
}

.cl-graph-total-dropdown-header {
  padding: 5px;
  border: 1px solid #1e9aec;
  cursor: pointer;
  border-radius: 4px;
  text-align: left;
  border-radius: 50px;
  background-color: #ffffff;
}

.cl-graph-total-dropdown-header-bottom {
  border-bottom: 1px solid gray;
  margin-bottom: 5px;
  display: inline-block;
  margin-left: 20px;
  color: #0d6efd;
}

.cl-graph-total-dropdown-header-bottom-sub-text {
  margin-left: 20px;
}

.cl-graph-total-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
  max-height: 200px; /* Adjust max height as needed */
  overflow-y: auto;
  border-radius: 4px;
}

.cl-graph-total-dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.cl-graph-total-dropdown-item:hover {
  background-color: #f0f0f0;
}
