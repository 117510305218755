.assets-info-section {
  margin-top: 24px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}

.assets-info-section .header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: linear-gradient(90deg, #00beaf 0%, #0298be 99.76%);
}

.assets-info-section .table-custom thead {
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.5em;
  color: #656565;
}

.assets-info-section .table-custom thead .img-thead {
  margin-right: 6px;
  margin-bottom: 2px;
}

.assets-info-section .table-custom .thead-td-custom {
  display: flex;
}

.assets-info-section .table-custom td {
  padding: 12px 24px;
}

.assets-info-section .table-custom .yes-tag {
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.5em;
  color: #212529;
  background: rgba(0, 209, 109, 0.05);
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
  border-radius: 6px;
}

.assets-info-section .table-custom .no-tag {
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.5em;
  color: #212529;
  background: rgba(219, 1, 40, 0.05);
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
  border-radius: 6px;
}

.assets-info-section .table-custom .suspected-tag {
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.5em;
  color: #212529;
  background: rgba(255, 153, 0, 0.05);
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
  border-radius: 6px;
}

@media (max-width: 768.99px) {
  .assets-info-section .table-custom td {
    padding: 12px 12px;
  }
}
