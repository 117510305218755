.up-saved-list-container {
  background-color: #f6f6f7;
  padding: 15px;
}

.up-saved-items-header {
  display: flex;
  background: rgba(234, 236, 240, 1);
  font-weight: bold;
  border-radius: 6px;
  margin-bottom: 10px;
  color: rgba(3, 128, 199, 1);
  width: 100%;
  border-radius: 6px;
}

.up-saved-items-header span {
  width: calc(92%/4);
  border-right: 1px solid #dde4f5;
  padding: 1%;
}

.up-saved-items-header span:last-child {
  width: 8%;
  border: none;
}

/* Row Styling */
.up-saved-row {
  display: flex;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  width: 100%;
}

.up-saved-row:hover {
  background: #f4f4f4;
}

/* Items inside the row */
.up-saved-item {
  text-align: left;
  font-size: 14px;
  width: calc(92%/4);
  border-right: 1px solid #EAECF0;
  padding: 1%;
}

.up-saved-row span:last-child {
  width: 8%;
  border: none;
  display: flex;
  justify-content: center;
}

/* Link Styling */
.up-saved-link {
  color: #007bff;
  text-decoration: none;
}

.up-saved-link:hover {
  text-decoration: underline;
}

/* Eye Icon */
.up-icon {
  margin-left: 10px;
  cursor: pointer;
}