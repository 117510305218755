.corporate-explore-insight-results-container {
  padding: 20px;
}

.corporate-explore-insight-results-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.corporate-explore-insight-results-tabs-container {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.tab {
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  color: #333;
}

.corporate-explore-insight-results-tab-count {
  font-size: 12px;
  background-color: #f0f0f0;
  padding: 3px 8px;
  border-radius: 12px;
  margin-left: 5px;
}
.tab-count{
      font-size: 12px;
    background-color: #f0f0f0;
    padding: 3px 8px;
    border-radius: 12px;
    margin-left: 5px;
}
.tab.active {
  border-bottom: 3px solid #000;
  font-weight: bold;
}

.tab:hover:not(.active) {
  background-color: #f9f9f9;
}

.corporate-explore-insight-results-cards-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.corporate-explore-insight-results-main-card {
  display: flex;
  background-color: white;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  align-items: center;
}
.corporate-explore-insight-results-main-card-status{
    font-weight: bold;
}
.corporate-explore-insight-results-main-card-status-companies:hover{
  text-decoration: underline;
  color: #419ae9;
}

.corporate-explore-insight-results-main-card-icon {
  margin-right: 25px;
  flex-shrink: 0;
}

.corporate-explore-insight-results-main-card-icon-placeholder {
  width: 50px;
  height: 50px;
  background-color: #faf9f9;
  border-radius: 4px;
  display: flex;
    box-shadow: 0 2px 5px rgba(185, 184, 184, 0.1);
  align-items: center;
  justify-content: center;
}

.corporate-explore-insight-results-main-card-icon-placeholder img {
  width: 24px;
  height: 24px;
}
.corporate-explore-insight-results-main-details-icon{
  font-size: 1rem;
}
.corporate-explore-insight-results-main-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 50%;
}

.corporate-explore-insight-results-main-card-content h2 {
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: bold;
  flex-basis: 100%;
}

.corporate-explore-insight-results-main-card-content h2:hover {
    color: #419ae9;
; /* Change this to whatever color you prefer */
    cursor: pointer; /* Shows a pointer cursor on hover */
}
.corporate-explore-insight-results-main-card-status-officers:hover{
    color: #419ae9;
     cursor: pointer;
     text-decoration: underline
; /* Change this to whatever color you prefer */
}
.corporate-explore-insight-results-main-card-content p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
  flex: 1 1 50%;
}

.corporate-explore-insight-results-main-card-content .status {
  display: inline-block;
  padding: 2px 8px;
  background-color: rgb(22, 160, 134);;
  color: white;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
}

.corporate-explore-insight-results-main-card-content .inactive.status {
  background-color: #F44336;
}
.separa {
  height: 4px;
  background-color: #0a0a0a;
  border: none;
  margin: 10px 0;
}
.corporate-explore-insight-results-main-card-status-comapnies {
  color: #00355f !important;
  text-decoration: none;
}

.corporate-explore-insight-results-main-card-status-comapnies:hover {
   text-decoration: underline;
    color: #00355f !important;

}
