/* Login Container */
.probe-login-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  padding-bottom: 10px;
  border-radius: 8px;
  margin: 0 auto;
  width: 80%;
}

.probe-login-container-inner-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.probe-login-container-logo-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.probe-login-container-logo {
  width: 26px;
  height: 34px;
}

.probe-login-email-section {}

/* Section Titles */
.probe-login-title {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  margin-top: 15px;
  margin: 8px 0;
}

/* Input Fields */
.probe-login-input {
  width: 100%;
  border-radius: 6px;
  padding: 4px 6px;
  border: 1px solid #D0D5DD;
  font-size: 13px;
}

.probe-login-input::placeholder {
  color: #D0D5DD;
}

.probe-login-other-options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 11px;
}

/* Google Button */
.google-auth-button {
  width: 100%;
  border: 1px solid #D0D5DD;
  padding: 4px 6px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.probe-reset-title {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
  margin: 8px 0;
}

.probe-login-error {
  font-weight: 300;
  font-size: 12px;
  margin-top: 1px;
  margin-bottom: 1px;
  color: red;
}