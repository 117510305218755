.tiles-x {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.people-close-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-size: cover;
}

.title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.title .count {
  font-size: 16px;
  font-weight: normal;
  color: #666;
  margin-left: 8px;
  background: #f0f0f0;
  border-radius: 50%;
  padding: 2px 8px;
}

.striped-table {
  width: 100%;
  border-collapse: collapse; /* Ensures no double borders */
  border-spacing: 0; /* Removes any default spacing between cells */
}

.corporate-Insights-people-table tr td {
  padding: 11px;
  vertical-align: top;
  background: #fbfbfb;
  font: 13px;
  text-shadow: 1px 1px 1px rgba(65, 64, 64, 0.004);
  border-spacing: 0;
  border: none; /* Ensures no borders are applied to table cells */
  border-bottom: 1px solid #e0e0e0; /* Add bottom border */
  border-collapse: collapse; /* Ensures no gaps between cells */
}

.corporate-Insights-people-table .user-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  /* background: url('/path-to-user-icon.png') no-repeat center center; */
  background-size: cover;
  margin-right: 10px;
}

.corporate-Insights-people-table a {
  font-weight: 600;
  color: #00355f;
  text-decoration: none;
}

.corporate-Insights-people-table a:hover {
  text-decoration: underline;
}

/* .corporate-Insights-people-table .desc {
  font-size: 14px;
  color: #666;
}

.corporate-Insights-people-table .flag {
  display: inline-block;
  width: 16px;
  height: 12px;
  margin-right: 5px;
  background-size: cover;
} */

.corporate-Insights-people-table .status-green,
.corporate-Insights-people-table .status-red {
  float: right;
  font-weight: 600;
  padding: 2px 6px;
  border-radius: 3px;
  color: #fff;
}

.corporate-Insights-people-table .status-green {
  background-color: #28a745;
}

.corporate-Insights-people-table .status-red {
  background-color: #dc3545;
}

.people-contacts-link-button {
  display: flex;
  align-items: center;
  margin-top: 20px;
  text-decoration: none;
  color: #00355f;
  font-weight: 600;
}

.people-contacts-link-button .chevron-right-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  /* background: url('/path-to-chevron-right-icon.png') no-repeat center center; */
  background-size: cover;
  margin-left: 5px;
}

.people-contacts-link-button:hover {
  text-decoration: underline;
}
