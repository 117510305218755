/* Style for table row hover effect */

.totalCustomer-info-section {
  margin-top: 24px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  width: 20%;
}

.totalCustomer-info-section .header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: white;
  color: black;
}
