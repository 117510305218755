.company-person-advance-filter-form-check-label {
    font-size: 14px;
    font-weight: 500;
}

.company-advance-filter-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.company-advance-filter-col {
    width: 48%;
}

@media (max-width: 430px) {
    .company-advance-filter-row {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .company-advance-filter-col {
        width: 100%;
    }
}