.main-quote-title {
  font-weight: 600;
  font-size: 3em;
  line-height: 1.5em;
}

.judgment_checker_responsive_image {
  width: 300px;
}

.probe-jc-input-search-button {
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.dashboard-section .main-div .dashboard-content-div .quote-div {
  display: flex;
  align-items: center;
  padding: 0px 150px 0px 72px;
  background: linear-gradient(92.74deg, #0466d1 0%, #00d1a9 100%);
  border-radius: 10px;
}

.dashboard-section .main-div .dashboard-content-div .quote-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-section .main-div .dashboard-content-div .first-parallel-div {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 40px;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div {
  border: 1px solid #e9e9e9;
  margin-top: 2px;
  background-color: white;
  border-radius: 10px;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div.card-full-width {
  width: 100%;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div.card-1 {
  max-width: 1150px;
  width: 100%;
  position: relative;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div.card-2 {
  max-width: 514px;
  width: 100%;
}

.video-container {
  padding: 5px;
  width: 100%;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div.card-6 {
  max-width: 514px;
  width: 100%;
  height: fit-content;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div.card-7 {
  max-width: 514px;
  width: 100%;
  background-color: #f5f5f5;
  margin-top: 0;
  border: none;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div.card-4 {
  max-width: 514px;
  width: 100%;
  height: fit-content;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div.card-3 {
  max-width: 1150;
  width: 100%;
  border: none;
  background-color: #f5f5f5;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div.card-3
  .select-service-cards {
  display: flex;
  align-items: stretch;
  gap: 32px;
  margin-top: 32px;
}
.select-service {
  display: flex;
  align-items: stretch;
  gap: 32px;
  margin-top: 32px;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div.card-3
  .select-service-cards
  .inner-card {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 26px;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  padding: 24px 16px 24px 24px;
  border: transparent;
}

.cardPerson {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  /* margin: 12px; */
  margin-top: 15px;
  overflow: hidden;
  border: 1px solid #cccccc;
  transition: height 0.5s ease-in-out;
  height: 250px;
}
/* @media (min-width: 360px) and (max-width: 430px) {
  .cardPerson{

 width: 100%;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;

  overflow: hidden;
  border: 1px solid #cccccc;
  transition: height 0.5s ease-in-out;


}
} */

.cardBusiness {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  /* margin: 12px; */
  margin-top: 15px;
  overflow: hidden;
  border: 1px solid #cccccc;
  transition: height 0.5s ease-in-out;
  height: 250px;
}
.bottom-rectangle,
.go-corner {
  background-color: #0466d1;
  width: calc(100% + 48px);
  border-radius: 4px 4px 0 0;
  position: absolute;
  bottom: 0;
  transform: skew(0deg);
  /* background-image: linear-gradient(-1deg, #8f479a 1%, #dc2a74 100%); */
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

.go-corner {
  top: 0;
  align-items: start;
}

.card:hover .bottom-rectangle,
.card:hover .go-corner {
  height: 16px;
}

.selected-card .bottom-rectangle,
.selected-card .go-corner {
  height: 16px;
  box-shadow:
    0px 4px 16px 0px rgba(0, 209, 169, 0.25),
    0px 4px 16px 0px rgba(4, 102, 209, 0.25);
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div
  .card-headers {
  padding: 24px;
  border: 1px solid #e9e9e9;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div.card-3
  .card-headers {
  background-color: #f5f5f5;
  border: none;
  padding: 0;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div
  .content-div {
  padding: 24px;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div
  .btn-div {
  margin-top: 32px;
}

.dashboard-section
  .main-div
  .dashboard-content-div
  .first-parallel-div
  .cards-div
  .btn-div
  button {
  border: 1px solid #0466d1;
  color: #0466d1;
  background-color: white;
  padding: 12px 24px;
  border-radius: 10px;
}

.dashboard-section .button-bottom {
  display: block;
}

.dashboard-section .inputs {
  border: 1px solid #e9e9e9;
  padding: 16px;
  border-radius: 4px;
  outline: none;
}

.dashboard-section .apply-btn {
  color: #0466d1;
  padding: 12px 24px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #0466d1;
  background-color: white;
}

.selected-card {
  border: 1px solid #0466d1 !important;
}

@media (min-width: 1600px) {
  .dashboard-section
    .main-div
    .dashboard-content-div
    .first-parallel-div
    .cards-div.card-3
    .select-service-cards
    .inner-card {
    padding: 16px;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .first-parallel-div
    .cards-div.card-3
    .select-service-cards
    .inner-card
    img {
    max-width: 140px;
  }
}

@media (max-width: 1440px) {
  .dashboard-section
    .main-div
    .dashboard-content-div
    .first-parallel-div
    .cards-div.card-3
    .select-service-cards
    .inner-card
    /* .video-container */
    img {
    max-width: 100px;
  }

  .dashboard-section .main-div .dashboard-content-div .quote-div {
    padding: 0px 72px 0px 72px;
  }
}

@media (max-width: 1300px) {
  .judgment_checker_responsive_image {
    width: 200px;
  }
  .dashboard-section
    .main-div
    .dashboard-content-div
    .first-parallel-div
    .cards-div.card-3
    .select-service-cards
    .inner-card 
    /* .video-container */ {
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 1280px) {
  .judgment_checker_responsive_image {
    display: none;
  }
}

@media (max-width: 1024px) {
  .probe-jc-input-search-button {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .dashboard-section .main-div .dashboard-content-div .first-parallel-div {
    flex-direction: column;
    gap: 0;
  }

  .dashboard-section .main-div .dashboard-content-div .quote-div {
    padding: 32px;
    flex-direction: column;
    text-align: center;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .first-parallel-div
    .cards-div.card-2 {
    max-width: 100%;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .first-parallel-div
    .cards-div.card-4 {
    max-width: 100%;
  }

  .dashboard-section
    .main-div
    .dashboard-content-div
    .quote-div
    .quote-img-div
    img {
    width: 100%;
  }
}

@media (max-width: 430px) {
  .judgment_checker_responsive_image {
    display: none;
  }

  #jc-business-probe-button-search-ch-api {
    width: 200px !important;
    height: 50px !important;
  }
  .light-text-color {
    justify-content: center; /* Center the content horizontally */
    text-align: center; /* Center the content text */
    align-items: center; /* Center the content vertically */
    flex-direction: row; /* Stack items vertically */
  }
  .quote-img-div {
    display: none;
  }

  .main-quote-title {
    font-weight: 500;
    font-size: 1.2em;
    line-height: 1.5em;
  }
}

@media (max-width: 576px) {
  .jc-business-probe-modal {
    max-width: 90% !important;
    flex-direction: column;
  }
  .dashboard-section
    .main-div
    .dashboard-content-div
    .first-parallel-div
    .cards-div.card-3
    .select-service-cards {
    flex-direction: column;
  }

  .dashboard-section .inputs {
    padding: 8px;
  }

  .dashboard-section .apply-btn {
    padding: 4px 8px;
  }
}

@media (max-width: 575px) {
  .jc-business-probe-modal {
    max-width: 90% !important;
    width: 87%;
  }
  .dashboard-section
    .main-div
    .dashboard-content-div
    .first-parallel-div
    .cards-div.card-3
    .select-service-cards {
    flex-direction: column;
  }

  .dashboard-section .inputs {
    padding: 8px;
  }

  .dashboard-section .apply-btn {
    padding: 4px 8px;
  }
}
@media (max-width: 850px) {
  .jc-business-probe-modal {
    max-width: 100% !important;
    flex-direction: column;
  }
  .dashboard-section
    .main-div
    .dashboard-content-div
    .first-parallel-div
    .cards-div.card-3
    .select-service-cards {
    flex-direction: column;
  }

  .dashboard-section .inputs {
    padding: 8px;
  }

  .dashboard-section .apply-btn {
    padding: 4px 8px;
  }
}
@media (min-width: 360px) and (max-width: 424px) {
  .cards-div.card-3 {
    margin-top: 2px;
  }
}
@media (min-width: 100px) and (max-width: 430px) {
  .judgement-checker-select-service {
    margin-top: 5px !important;
  }
  .judgement-checker-select-service-cards {
    margin-top: 5px !important;
    gap: 2px !important;
  }
  .judgement-checker-inner-card {
    flex-direction: row !important;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    padding: 3px !important;
  }
  .cardPerson {
    height: 110px;
    margin-top: 2px !important;
  }

  .cardBusiness {
    height: 110px;
    margin-top: 1px !important;
  }

  .judgement-checker-cards-top-margin {
    margin-top: 1px !important;
  }
  .judgement-checker-main-bottom-div {
    padding-bottom: 20px !important;
    padding-left: 10px !important;
  }
  .judgement-checker-select-the-service-text-margin-top {
    margin-top: 1px !important;
  }
}
