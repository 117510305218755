.powered-by-trust-online-rounded-card {
  background-color: #f5f5f5; /* Background color of the card */
  border-radius: 10px; /* Rounded edges */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); /* Small shadow */
}

.powered-by-trust-online-rounded-card-title {
  font-size: small;
}

.powered-by-trust-online-rounded-card-content {
  font-weight: 350;
}

.powered-by-trust-online-rounded-card-body {
  background: linear-gradient(92.74deg, #0466d1, #00d1a9);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: 150px;
}
.poweredByTrustOnline-Register {
  margin-left: 10px;
}
.poweredByTrustOnline-text {
  margin-left: 11.5px;
}
.poweredByTrustOnline-cards {
  margin-left: 8px;
}
@media (max-width: 1728px) {
  .powered-by-trust-online-rounded-card-body {
    background: linear-gradient(92.74deg, #0466d1, #00d1a9);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height: 200px;
  }
}
@media (max-width: 1660px) {
  .powered-by-trust-online-rounded-card-body {
    background: linear-gradient(92.74deg, #0466d1, #00d1a9);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height: 100px;
  }
}

@media (max-width: 1018px) {
  .powered-by-trust-online-rounded-card-body {
    background: linear-gradient(92.74deg, #0466d1, #00d1a9);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height: 220px;
  }
}

@media (max-width: 11300px) {
  .powered-by-trust-online-rounded-card-body {
    background: linear-gradient(92.74deg, #0466d1, #00d1a9);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height: 190px;
  }
}

@media (max-width: 1340px) {
  .powered-by-trust-online-rounded-card-body {
    background: linear-gradient(92.74deg, #0466d1, #00d1a9);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height: 210px;
  }
}

@media (max-width: 997px) {
  .powered-by-trust-online-rounded-card-body {
    background: linear-gradient(92.74deg, #0466d1, #00d1a9);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height: 220px;
  }
}

@media (max-width: 767px) {
  .powered-by-trust-online-rounded-card-body {
    background: linear-gradient(92.74deg, #0466d1, #00d1a9);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height: 150px;
  }
}

/* @media (min-width: 280px) and (max-width: 414px) {
  .powered-by-trust-online-rounded-card-body {
    background: linear-gradient(92.74deg, #0466d1, #00d1a9);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    height: 190px;
    width: 110%;
    margin-left: -8px
  }
} */

/* Default styles for desktop and larger screens */
.powered-by-trust-online-rounded-card-body {
  background: linear-gradient(92.74deg, #0466d1, #00d1a9);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: 190px;
  width: 100%;
  margin: 0;
  padding: 10px; /* Default padding for desktop */
}

.row > [class*='col-'] {
  padding: 5px; /* Default padding for desktop */
}

/* Styles for mobile view (280px to 414px) */
@media (min-width: 280px) and (max-width: 414px) {
  .powered-by-trust-online-rounded-card-body {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .poweredByTrustOnline-cards {
    padding: 0 -7px;
  }

  .row > [class*='col-'] {
    padding: 0;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .PoweredByTrustOnline-card {
    padding: 2px 10px 2px 10px;
  }
}
/* Custom class for the image */
.img-small-on-large-screen {
  width: 100%; /* Default width, adjust as needed */
  height: auto; /* Keep the aspect ratio */
}

/* Media query for screens wider than 1325px */
@media (min-width: 1325px) {
  .img-small-on-large-screen {
    width: 100%;
    height: auto;
  }
}

/* Media query for screens wider than 1325px */
@media (max-width: 768px) {
  .img-small-on-large-screen {
    width: 50%;
    height: auto;
    margin-right: 8%;
  }
}
