.parallel-input-div {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'flex-start';
  gap: '1px';
}

.JudgementCheckerBusiness-continue {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}
@media (min-width: 344px) and (max-width: 400px) {
  #jc-business-probe-button-search-ch-api {
    width: 100% !important;
    height: 55px !important;
    font-size: '14px';
  }
  .JudgementCheckerBusiness-continue {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
#jc-business-probe-button-search-ch-api{
   display: flex;
  justify-content: center;
  width: 100%;
  height: 40px !important;

}
.check-business{
  height: 51px;
  margin-top: 13px;
}
.judgement-checker-business-company-name-input{
  width: 104% !important;
}
}

@media (max-width: 450px) {
  .judgement-checker-business-company-name-input::placeholder {
    font-size: small;
  }
}


