.Garph-button.primary {
  border: 2px;
}

.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(2, 156, 189);
}

.button-text {
  color: black;
  font-size: 12px;
  font-weight: 400;
}