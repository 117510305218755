/* Main container */
.contact-us-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  background-image: linear-gradient(to bottom right, #0466d1cc, #00d1a9cc),
    url('https://s3-alpha-sig.figma.com/img/07a7/42ba/455ab0e466c8aa901688bec6f2475a67?Expires=1740355200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=Uz5qwUTZ1C-23wwXLGbl5alYhEaJDg0kXwiqBlyEz64HtUy-mRH5uutyvtwphtCsxTqE60Hw1~jPjuJNKjeQd7pL5WI~wNkjJC12992u95trF1M9lB~qa8ym8SXUJGFebjfvdohgoAAj9mpgTW6pJsAg5ay3E~T23U4ixLsJEw3GGAMK3g7Re5XBjz41wGA3r72NovZcWVaHzkvnTegIRCszObjBfcMdaUw2DJvDAGARCbwM90GEUAuF7wOPowTIBIeAAJ7pHxV2CbwTMF8Gv6B8M-ZPjd~UZvCz8dPPjp6OoA6gfeWCHZkW~SEpQHcpfgKhiEa2OHBSuilCVZWPBw__');
  padding: 50px 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Left section */
.contact-us-left {
  flex: 1;
  color: white;
  font-size: 1.8rem;
  font-weight: bold;
  max-width: 500px;
  text-align: left;
}

/* Right section */
.contact-us-right {
  flex: 1;
  background: white;
  padding: 40px;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-us-heading {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Form styling */
.contact-us-form {
  display: flex;
  flex-direction: column;
}

.contact-us-form-group {
  display: flex;
  gap: 10px;
}

.contact-us-label {
  font-size: 14px;
}

.contact-us-input {
  flex: 1;
  padding: 6px 0;
  padding-left: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

/* Checkboxes */
.contact-us-services {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
}

.contact-us-checkbox {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  width: 45%;
  gap: 10px;
  cursor: pointer;
}

/* Textarea */
.contact-us-textarea {
  width: 100%;
  height: 100px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
}

/* Submit button */
.contact-us-button {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.contact-us-button:hover {
  background-color: #155ab6;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .contact-us-container {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .contact-us-left {
    text-align: center;
    font-size: 1.5rem;
    padding-bottom: 20px;
  }

  .contact-us-right {
    width: 100%;
    max-width: 600px;
  }

  .contact-us-form-group {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .contact-us-left {
    font-size: 1.3rem;
  }

  .contact-us-right {
    padding: 30px;
  }

  .contact-us-input,
  .contact-us-textarea {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .contact-us-left {
    font-size: 1.1rem;
  }

  .contact-us-right {
    padding: 20px;
  }

  .contact-us-input,
  .contact-us-textarea {
    padding: 10px;
  }

  .contact-us-button {
    padding: 10px;
    font-size: 0.9rem;
  }
}