h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.corporate-explore-key-data-title {
  font-size: 18px !important;
  color: #021b41;
}

.corporate-explore-keyData-company-description {
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 21px;
}

.main-btns {
  display: flex;
  margin-bottom: 20px;
}

.corporate-explore-keyData-report-btn {
  color: #fff;
  background: linear-gradient(99.78deg, #0466d1 0%, #00d1a9 152.3%);
  cursor: pointer;
  width: 150px;
  height: 27px;
  border-radius: 30px !important;
  border: none;
}

.corporate-explore-keyData-report-btn:disabled {
  color: #8a8a8a;
  background: #CFCFCF;
  cursor: not-allowed;
  border: none;
}

.corporate-explore-ccj-report-btn {
  color: #fff;
  background: linear-gradient(99.78deg, #0466d1 0%, #00d1a9 152.3%);
  cursor: pointer;
  width: 150px;
  height: 27px;
  border-radius: 30px !important;
  border: none;
  margin-left: 10px;
}

.corporate-explore-keyData-upgrade-btn {
  color: #fff;
  background: linear-gradient(99.78deg, #0466d1 0%, #00d1a9 152.3%);
  cursor: pointer;
  width: 170px;
  height: 27px;
  border-radius: 30px !important;
  margin-left: 10px;
  border: none;
}

.corporate-explore-keyData-upgrade-btn:disabled {
  color: #8a8a8a;
  background: #CFCFCF;
  cursor: not-allowed;
  border: none;
}


.corporate-explore-keyData-company-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 20px;
}

.info-item {
  display: flex;
  flex-direction: column;
}

.info-item strong {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.info-item span {
  font-size: 16px !important;
  font-weight: 600;
}

.info-item small {
  font-size: 12px;
  color: gray;
}

.status-active {
  color: #16a086;
  display: inline-block;
  border: 2px solid;
  padding: 1px 8px;
  font-weight: 800;
  width: 30%;
  border-radius: 5px;
  font-size: 12px;
}

.status-submitted {
  color: #0466d1;
}

.corporate-explore-key-data-title {
  margin-top: 20px;
}

@media (min-width: 300px) and (max-width: 620px) {
  .corporate-explore-keyData-company-info {
    display: flex;
    flex-direction: column;
  }

  .main-btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .corporate-explore-keyData-report-btn {
    margin-bottom: 5px;
  }

  .corporate-explore-keyData-upgrade-btn {
    margin-bottom: 5px;
    margin-left: 0px;
  }

  .corporate-explore-ccj-report-btn {
    margin-bottom: 5px;
    margin-left: 0px !important;
  }
}