/* Modal Overlay */
.user-auth-handler-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Parent Container */
.user-auth-handler-parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Ensure it spans the full viewport height */
  width: 100vw;
  /* Ensure it spans the full viewport width */
  position: fixed;
  /* Fix the position to make it always centered */
  top: 0;
  left: 0;
  z-index: 1000;
  /* Ensure it stays on top of other elements */
}

.user-auth-handler-main-container {
  display: flex;
  justify-content: space-between;
  width: 75%;
}

/* Modal Content */
.user-auth-handler-modal-content {
  background: white;
  padding: 40px;
  border-radius: 8px;
  width: 50%;
  /* max-width: 90%; */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Close Button */
.user-auth-handler-modal-content button {
  background-color: #00D1A9;
  color: white;
  border: none;
  padding: 4px 6px;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 8px;
}

.user-auth-handler-toggle-button {
  color: #00D1A9;
}

.user-auth-handler-toggle-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.user-auth-handler-modal-content button:hover {
  background-color: #00D1A9;
}

.user-auth-handler-toggle-text {
  text-align: center;
  margin-top: 10px;
}

.user-auth-photo-container {
  height: 90vh;
  width: 50%;
  margin: 0 auto;
  /* Center the div horizontally */
  background-image: url('https://s3-alpha-sig.figma.com/img/229f/135c/b70c28ee3d935b9d99041ef65a2184e1?Expires=1737936000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jW3NpRCMkPPA4w80bshSAZdb6dCr3-lGKcvG61WgTQW~K52iA9hZ4CPIWm5UQeOo2LHnULYTT5~GGLW4vBQrxT2MWUZLCCb3fyEwXt41eAx-obj8U01qgu08adH9T-EzfD2SKJStUtLCwjJkyMBRxipmyrb9IKB9tjGFqsf-YaPFtQIoJvgxij7O6S7mBuGFnjYc5GYsjmBE-JQ7o-m-NyUb1WGIsAqN7o17ZByLyJYrebEjjrHOgzNllSGjOV7LkzJFQIJe6P4zbkq7m~9uOc3uyt-dqs5n61dGTAIhGxBIflxu15y5AJ6YKZhDzlJSqjLl3yGJLZygZVQjaG6CLQ__');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  padding: 3%;
}

.user-auth-photo-container-inner {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  padding: 4%;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.user-auth-photo-banner {
  width: 100%;
  height: 60%;
  background-image: url("https://s3-alpha-sig.figma.com/img/8775/d896/35f7fd04027b5a69014f856c6714e703?Expires=1737936000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=G9adP~9HJR0qerYnkdiGhXxXNGg1Ld-eqBOCdyepvpsQipU7E1djCiaaY3VHpOur9flAGoM8pMggtR50clynuCjGNC5KbXgZkPAVxBFP1u4cXP9qvbMb52Mnla5BUB9CPG98RroRQV-Qx1EFecUnGZJzV5qvlXno5Dauv3ExaBOFWm-Sixp9IN3IhZrh-gWJITB9vC7tyYi2vPHW~EvVHKUdEQH1KJ5QlEC2YOK5KDATVxsoWJBTN6l0dzGEm057scIXvUS3P-YJLZXYadtd3j19nKmXOVCsnvgxK0is-XlBnpfZKa9d~5HN30N8YuWrxaBikG2L0Hf2Byw2MY0dWA__");
  /* Replace with your image URL */
  background-size: cover;
  border-radius: 10px;
}

.user-auth-photo-heading {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #FFFFFF;
}

.user-auth-photo-text {
  margin-top: 10px;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
}

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  /* Adjust spacing as needed */
  gap: 5px;
  /* Adjust spacing between dots */
}

.dots span {
  width: 10px;
  /* Size of the dots */
  height: 10px;
  background-color: white;
  /* Adjust the color as needed */
  border-radius: 50%;
}

.dots span:nth-child(2) {
  width: 38px;
  /* For the middle long dot */
  border-radius: 10px;
  /* Makes it an oval */
}

@media (max-width: 815px) {
  .user-auth-handler-modal-content {
    width: 100%;
    padding: 0;
  }

  .user-auth-photo-container {
    display: none;
  }
}