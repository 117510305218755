.banner-modal-close-button {
  border-color: aliceblue;
}

.banner-section {
  background: linear-gradient(99.78deg, #0466d1 0%, #00d1a9 152.3%);
  padding: 50px 160px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.banner-section .banner-outer-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner-section .banner-outer-div .banner-left {
  max-width: 780px;
  width: 100%;
}

.banner-section .banner-outer-div .banner-right {
  max-width: 700px;
  width: 100%;
}

.banner-section .banner-outer-div .banner-right img {
  width: 100%;
}
.Judgements-fines-btn {
  display: 'flex';
  align-items: 'center';
  margin-bottom: '0px !important';
}

.banner-section .banner-outer-div .banner-left button {
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  margin: 48px 0;
}

.banner-section .banner-outer-div .banner-left .features-outer-div {
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
  gap: 16px;
}

.banner-section
  .banner-outer-div
  .banner-left
  .features-outer-div
  .features-inner-div {
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 280px;
  width: 100%;
}

@media (max-width: 1600.99px) {
  .banner-section {
    padding: 50px 60px;
  }
}

@media (max-width: 1024.99px) {
  .banner-section .banner-outer-div {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
  }
}

@media (max-width: 768.99px) {
  .banner-section {
    padding: 25px 24px;
  }
}

@media (max-width: 575.99px) {
  .banner-section .banner-outer-div .banner-left .features-outer-div {
    flex-wrap: wrap;
  }
}

@media (min-width: 344px) and (max-width: 768px) {
  .Judgements-fines-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
