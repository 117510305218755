.judgement_check_terms_conditions_policy_hyper_links {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.judgement_check_privacy_policy_phone {
  font-weight: bold;
}

.judgement_check_privacy_policy_operations_manger {
  font-style: italic;
}
