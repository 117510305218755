/* Parent component CSS - Make sure it has a relative position */
.parent-component {
  position: relative;
  /* Other styles for the parent component */
}

/* Loading container CSS */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Changed from absolute to fixed */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 4000; /* High z-index to be on top of other elements */
}

/* Loading spinner CSS */
.loading-spinner {
  /* Add styling for the spinner or loading message here */
}
