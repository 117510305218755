.layout-dashboard-table-parallel {
  display: flex;
  gap: 24px;
}

.layout-dashboard-table-parallel .inner-div {
  flex: 1;
}

.summary-report-bottom-btn-div {
  position: absolute;
  bottom: 48px;
  right: 60px;
  background: #0466d1;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.5em;
  color: #ffffff;
  border-radius: 10px;
  padding: 12px 72px;
  border: none;
  outline: none;
  border: 1px solid #0466d1;
}

.summary-report-back-btn-div {
  position: absolute;
  bottom: 48px;
  left: 60px;
  background: transparent;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.5em;
  color: #0466d1;
  border-radius: 10px;
  padding: 12px 72px;
  border: 1px solid #0466d1;
  outline: none;
}

.summary-report-startover-btn-div {
  position: absolute;
  bottom: 48px;
  left: 280px;
  background: #00d1a9;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.5em;
  color: #ffffff;
  border-radius: 10px;
  padding: 12px 72px;
  border: none;
  outline: none;
  border: 1px solid #00d1a9;
}

@media (max-width: 1366.99px) {
  .layout-dashboard-table-parallel {
    flex-direction: column;
  }

  .summary-report-startover-btn-div,
  .summary-report-back-btn-div,
  .summary-report-bottom-btn-div {
    padding: 10px 54px;
  }

  .summary-report-startover-btn-div {
    left: 198px;
  }

  .summary-report-bottom-btn-div {
    right: 20px;
  }

  .summary-report-back-btn-div {
    left: 20px;
  }
}

@media (max-width: 1024.99px) {
  .summary-report-startover-btn-div,
  .summary-report-back-btn-div,
  .summary-report-bottom-btn-div {
    padding: 6px 24px;
    /* font-size: 16px;
        line-height: 24px; */
    bottom: 86px;
  }

  .summary-report-startover-btn-div {
    left: 117px;
  }
}

@media (max-width: 414.99px) {
  .summary-report-startover-btn-div {
    padding: 6px 12px;
    bottom: 40px;
    left: 20px;
  }

  .summary-report-back-btn-div {
    padding: 6px 12px;
    left: 20px;
  }

  .summary-report-bottom-btn-div {
    padding: 6px 12px;
  }
}
