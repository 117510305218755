.financials-title {
  margin-bottom: 20px;
  font-size: 24px;
  color: #021b41;
}

.financials-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}

.financial-item {
  width: 50%;
  float: left;
  min-height: 65px;
}
.value {
  font: 10px;
  margin-bottom: 0px;
}

.financial-item .label {
  font-weight: 600;
  margin-bottom: -2px;
}
.label {
  font-size: 13px;
  display: block;
  margin-bottom: -5px !important;
}

.financial-item .value {
  margin-right: 10px;
  display: inline-block;
  font-size: 22px;
  vertical-align: middle;
}

.text-info {
  color: #000 !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-primary {
  color: #000 !important;
}

.view-financials-link {
  color: #416171;
  font-weight: 600;
}

.view-financials-link:hover {
  text-decoration: underline;
}

@media (min-width: 300px) and (max-width: 620px) {
  .financials-container {
    display: flex;
    flex-direction: column;
  }

  .financials-content {
    display: flex;
    flex-direction: column;
  }
  .financials-area-chart {
    width: 300px;
    height: 150px;
  }
}
