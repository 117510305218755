.name-div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.name-div .make-a-suggestion-div button {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: 1px solid #00d1a9;
  padding: 12px 24px;
  border-radius: 10px;
  color: #00d1a9;
  margin-left: 10px;
}

.name-div .make-a-suggestion-div-mobile button {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: 1px solid #00d1a9;
  padding: 12px 24px;
  border-radius: 10px;
  color: #00d1a9;
  display: none;
}

.dashboard-section .main-div .header .name-div .dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  outline: none;
}

.dashboard-section .main-div .header .name-div .dropdown .dropdown-toggle:focus {
  box-shadow: 0 0 0 0;
}

.dashboard-section .main-div .header .name-div .dropdown .dropdown-menu {
  padding: 0px 0px;
}

.dashboard-section .main-div .header .name-div .dropdown .dropdown-toggle .initials {
  border-radius: 50%;
  margin-right: 16px;
  height: 50px;
  width: 50px;
  border: 2px solid transparent;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #0466d1, #00d1a9);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: inline-block;
  overflow: hidden;
}

.dashboard-section .main-div .header .name-div .dropdown .dropdown-toggle .initials img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

Link {
  border-bottom: none;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.dropdown-item:hover {
  background-image: linear-gradient(to right, #0467d183, #00d1ab96);
}

.dropdown-item li {
  font-weight: 600;
  font-size: 1em;
  line-height: 1.5em;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 3px 0px;
  cursor: pointer;
}

.dropdown-item-text{
  font-size: 14px;
  padding: 0;
  padding-left: 5px;
  font-weight: normal;
}

.dropdown-profile {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-name {
  font-weight: bold;
}

.profile-email {
  font-size: 12px;
  color: gray;
}


@media (max-width: 1024.99px) {
  .name-div {
    gap: 16px;
  }
}

@media (max-width: 768.99px) {
  .dashboard-section .main-div .header .name-div .dropdown .dropdown-toggle .initials {
    margin-right: 5px;
  }

  .dropdown-item li {
    /* font-size: 12px;
        line-height: 21px; */
    padding: 6px 0px;
  }

  .name-div {
    flex-direction: column;
    gap: 0px;
  }

  .name-div .make-a-suggestion-div button {
    padding: 10px 12px;
    /* font-size: 12px; */
    display: none;
  }

  .name-div .make-a-suggestion-div-mobile button {
    padding: 10px 12px;
    /* font-size: 12px; */
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 600px) {
  .dashboard-section .main-div .header .name-greetings {
    display: none;
  }
}

/* Hide the shorter text by default */
.help-text-small {
  display: none;
}

/* When the screen size is less than or equal to 768px, hide the longer text and show the shorter text */
@media (max-width: 1300px) {
  .help-text-large {
    display: none;
  }

  .help-text-small {
    display: inline;
  }
}