.one_final_check_box_main_div {
  display: flex;
  gap: 30px;
}

@media (max-width: 1200px) {
  .one_final_check_box_main_div {
    display: flex;
    gap: 10px;
  }
}
