.ce_filter_mid_input_main {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.ce_filter_mid_input_search {
  width: 100%;
  border: none;
  outline: none;
  box-shadow: none;
}

.ce_filter_mid_input_main_search {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid black;
}

.ce_filter_mid_input_label {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-bottom: 1px solid rgb(214, 214, 214);
  justify-content: space-between;
}

.ce_filter_mid_input_label:hover {
  background-color: gainsboro;
}

.ce_filter_mid_input_main_contain_or_not {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.ce_filter_mid_input_main_contain_or_not_label {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-bottom: 1px solid rgb(214, 214, 214);
  justify-content: space-between;
}

.ce_filter_mid_input_main_contain_or_not_label:hover {
  background-color: gainsboro;
  cursor: pointer;
}

.ce_filter_mid_input_label_main {
  cursor: pointer;
}

.ce_filter_mid_input_main_mid_ui {
  overflow-y: auto;
  max-height: 100%;
  width: 100%;
}

.filter-mid-input-search-on-filtered-data:hover {
  background-color: rgb(241, 239, 239);
}
