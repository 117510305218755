.corporate-explorer-property-land-building-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.corporate-explorer-property-land-building-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.corporate-explorer-property-land-building-count {
  background-color: #28a745;
  color: #fff;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 12px;
}

.corporate-explorer-property-land-building-striped-table table {
  width: 100%;
  border-collapse: collapse;
}

.corporate-explorer-property-land-building-striped-table th,
.corporate-explorer-property-land-building-striped-table td {
  padding: 10px;
  text-align: left;
}

.corporate-explorer-property-land-building-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.corporate-explorer-property-land-building-title {
  font-size: 16px;
  border: 0;
  margin-top: 6px;
}

.corporate-explorer-property-land-building-count {
  display: inline-block;
  padding: 0px 4px;
  border-radius: 4px;
  margin-left: 4px;
  font-size: 11px;
  font-weight: 600;
  background: #656e73;
  color: #fff;
  vertical-align: top;
  margin-top: 4px;
}

.corporate-explorer-property-land-building-striped-table {
  animation: none !important;
  padding: 10px 1px;
  max-height: 200px;
  overflow-y: auto;
}

.corporate-explorer-property-land-building-striped-table::-webkit-scrollbar {
  width: 8px;
}

.corporate-explorer-property-land-building-striped-table::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 4px;
}

.corporate-explorer-property-land-building-striped-table::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.corporate-explorer-property-land-building-striped-table {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}
.corporate-explorer-property-land-building-striped-table tr:first-child td {
  font-weight: 600;
  border-top: none;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 10px;
}

.corporate-explorer-property-land-building-striped-table td {
  padding: 11px;
  border-top: 1px solid #f0f2f3;
  vertical-align: top;
}

.corporate-explorer-property-land-building-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

th,
td {
  padding: 12px 16px;
  text-align: left;
  border: none;
}

th {
  background-color: #fff;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  border-bottom: none;
}
tr:nth-child(even) {
  background-color: #f3f3f3;
}
.corporate-explorer-property-land-building-building-icon {
  margin-right: 8px;
  margin-bottom: 6px;
}

tbody tr:last-child td {
  border-bottom: none;
}

@media (max-width: 600px) {
  th,
  td {
    font-size: 12px;
    padding: 8px;
  }

  .corporate-explorer-property-land-building-building-icon {
    margin-right: 4px;
  }
}
