.corporate-links-graph-left-sidebar-main {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: flex-start;
  color: white;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  padding-right: 15px !important;
  padding: 0px 2px 0px 0px;
  transition: background-color 0.3s ease;
  width: 100%; /* Ensure the items take full width */
  white-space: nowrap;
}
@media (min-width: 100px) and (max-width: 768px) {
  .corporate-links-graph-left-sidebar-main {
    display: none;
  }
}
