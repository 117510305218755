.cororate-explore-insight-person-main {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 20px;
}
.cororate-explore-insight-person-main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
