.dashboard-section {
  display: flex;
  margin-top: 6px;

  position: relative;
}

.dashboard-section .main-div {
  width: 100%;
}

.dashboard-section .main-div .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 16px 60px;
}

.dashboard-section .main-div .header .header-heading {
  font-size: 1.5em;
  font-weight: 600;
  line-height: 1.5em;
}

.dashboard-content-div .dashboard-graphics {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 1.5em;
  color: #212529;
  margin: 0 auto;
  margin-top: 40px;
  max-width: 360px;
}

.dashboard-content-div .dashboard-graphics img {
  width: 100%;
}

.dashboard-section .main-div .dashboard-content-div {
  background-color: #f5f5f5;
  /* padding: 40px 20px 132px; */
  padding: 20px !important;
  position: relative;
  z-index: 0;
}

@media (max-width: 1600.99px) {
  .dashboard-section .main-div .dashboard-content-div {
    padding: 20px 20px 140px;
  }
}

@media (max-width: 1366.99px) {
  .bottom-btn-div {
    padding: 10px 54px;
    right: 20px;
  }

  .dashboard-content-div .dashboard-graphics {
    /* font-size: 20px;
        line-height: 28px; */
  }
}

@media (max-width: 1024.99px) {
  .bottom-btn-div {
    padding: 6px 24px;
    /* font-size: 16px;
        line-height: 24px; */
  }

  .dashboard-content-div .dashboard-graphics {
    display: none;
  }
}

@media (max-width: 768.99px) {
  .dashboard-section .main-div .header {
    padding: 15px 48px;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 414.99px) {
  .dashboard-section .main-div .header {
    padding: 22px 5px 16px 15px;
    display: flex;
    justify-content: space-between;
  }

  /* .dashboard-section .main-div .header .header-heading {
    font-size: 20px;
        line-height: 32px;
  } */
}
