.probe-login-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    padding-bottom: 10px;
    border-radius: 8px;
    margin: 0 auto;
    width: 80%;
}

.probe-login-container-inner-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.probe-login-container-logo-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.probe-login-container-logo {
    width: 26px;
    height: 34px;
}

/* Section Titles */
.probe-login-title {
    text-align: center;
    font-size: 23px;
    font-weight: 600;
    margin-top: 15px;
    margin: 8px 0;
}

.code-input-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
}

.code-input {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.code-input:focus {
    border-color: #00D1A9;
    box-shadow: 0 0 5px #00D1A9;
}

.probe-login-input::placeholder {
    color: #D0D5DD;
}

.probe-login-other-options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 11px;
}