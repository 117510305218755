.sic_code_drop_tree_parent_name:hover {
  background-color: rgb(238, 238, 238);
  color: #707070;
  transform: translateX(
    5px
  ); /* Adjust the value to move the text more or less */
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease; /* Adds a smooth transition effect */
}

.sic_code_drop_tree_icon {
  transition: transform 0.3s ease;
}

.icon-open {
  transform: rotate(90deg); /* Rotate right when open */
}

.icon-closed {
  transform: rotate(0deg); /* Default state, pointing right */
}

.sic_code_max_div {
  width: 100%;
  max-width: 100%;
  overflow: hidden; /* Ensure the hidden content does not affect layout */
  transition: max-height 0.8s ease; /* Smooth transition for max-height */
}

.sic_code_max_div.collapsed {
  max-height: 0; /* When collapsed, max-height is 0 */
}

.sic_code_max_div.expanded {
  max-height: 1000px; /* Set a max-height large enough to contain the content */
}

.icon-container:hover .icon-open {
  transform: rotate(-90deg); /* Rotate back to closed on hover */
}

.icon-container:hover .icon-closed {
  transform: rotate(90deg); /* Rotate to open on hover */
}
