.up-record-list-container {
  background-color: #f6f6f7;
  padding: 15px;
}

.up-mp-record-header {
  display: flex;
  /* padding: 12px 0px;
  padding-left: 12px; */
  background: rgba(4, 102, 209, 0.05);
  font-weight: bold;
  border-radius: 6px;
  margin-bottom: 10px;
  color: rgba(3, 128, 199, 1);
  border-radius: 6px;
  width: 100%;
}

.up-mp-column-title {
  width: calc(100%/6);
  padding: 1%;
}

/* Row Styling */
.up-mp-record-row {
  display: flex;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  width: 100%;
}

.up-mp-record-row:hover {
  background: #f4f4f4;
}

/* Items inside the row */
.up-mp-record-item {
  text-align: left;
  font-size: 14px;
  width: calc(100%/6);
  border-right: 1px solid #EAECF0;
  padding: 1%;
  word-break: break-all;
  overflow-wrap: break-word
}

/* Link Styling */
.up-record-link {
  color: #007bff;
  text-decoration: none;
}

.up-record-link:hover {
  text-decoration: underline;
}

/* Eye Icon */
.up-icon {
  margin-left: 10px;
  cursor: pointer;
}