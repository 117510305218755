.suggestions-list {
  position: absolute;
  z-index: 1000; /* Ensures the dropdown appears above other content */
  width: 30%; /* Match the input width */
  background: rgb(255, 255, 255);
  border: 1px solid #ccc;
  border-top: none; /* Seamless transition from input to dropdown */
  list-style: none;
  padding: 5px; /* Add padding inside the card */
  margin-top: 0;

  /* Card-like styles */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
  transition: all 0.3s ease; /* Smooth transition for hover or open effects */
}

.suggestions-list li {
  padding: 4px 6px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.suggestions-list li:hover {
  background-color: #f8f8f8;
}
.search-suggestions-companies {
  color: rgb(11, 147, 238);
}
.search-suggestions-directors {
  color: rgb(11, 147, 238);
}
.suggestions-list-bold {
  font-weight: bold;
}
