.user-profile-sidebar {
  height: 100%;
  background: #fff;
  display: flex;
  border-right: 1px solid #d0d5dd;
  flex-direction: column;
  padding: 20px;
}

.user-profile-sidebar-search-container {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.user-profile-sidebar-search {
  border: none;
}

.user-profile-sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.user-profile-sidebar-menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  color: #555;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
}

.user-profile-sidebar-menu-item:hover {
  background: #f5f5f5;
}

.user-profile-sidebar-icon {
  margin-right: 10px;
}

.user-profile-sidebar-menu-item.active {
  background: #0052cc;
  color: #fff;
}

.user-profile-sidebar-profile {
  display: flex;
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.user-profile-sidebar-profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

.user-profile-sidebar-profile-info {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.user-profile-sidebar-profile-name {
  font-weight: bold;
  color: #333;
}

.user-profile-sidebar-profile-email {
  font-size: 14px;
  color: #777;
}
