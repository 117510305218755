.user-profile-dashboard-container {
  margin: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
}

.user-profile-dashboard-title {
  font-size: 1.5em;
  margin-bottom: 0px;
  font-weight: bold;
}

.user-profile-input-group {
  margin-bottom: 15px;
  display: flex;
  width: 70%;
}

.user-profile-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  width: 40%;
}

.user-profile-input-field {
  width: 60%;
  padding: 8px;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  background-color: #fcfcfd;
}

.user-profile-name-fields {
  display: flex;
  gap: 10px;
  width: 60%;
}

.user-profile-photo-upload {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

.user-profile-file-input {
  margin-left: 20px;
}

.user-profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 10px;
}

.user-profile-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 5%;
}

.user-profile-cancel-button {
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer !important;
}

.user-profile-save-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: not-allowed;
}

.user-profile-save-button:disabled {
  background: grey;
  cursor: not-allowed;
}

.upload-container {
  border: 2px solid #007bff;
  border-radius: 10px;
  padding: 5px 15%;
  text-align: center;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hidden-input {
  display: none;
}

.label {
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.upload-icon {
  font-size: 24px;
  margin-bottom: 10px;
}

.upload-text {
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
}

.supported-formats {
  font-size: 12px;
  color: #6c757d;
}

.file-preview {
  width: 100%;
  text-align: center;
}

.preview-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 5px;
}

.file-tag {
  background-color: #007bff;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
}