.corporate-explore-insight-companies-container {
  width: 100%;
  border-radius: 8px;
  height: 50vh;
  overflow-y: auto;
  background-color: #fff;
}

.corporate-explore-insight-companies-container-row {
  background-color: white;
}

/* WebKit scrollbar styles */
.corporate-explore-insight-companies-container::-webkit-scrollbar {
  width: 8px;
}

.corporate-explore-insight-companies-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.corporate-explore-insight-companies-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

.corporate-explore-insight-companies-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.corporate-explore-insight-companies-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.corporate-explore-insight-companies-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}

.corporate-explore-insight-companies-table {
  /* width: 90%; */
  border-collapse: collapse;
  margin-bottom: 12px;
}

.corporate-explore-insight-companies-table th,
.corporate-explore-insight-companies-table td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #e1e5eb;
}

.corporate-explore-insight-company-name {
  display: flex;
  align-items: center;
}

.corporate-explore-insight-company-icon {
  width: 20px;
  height: 20px;
  background-size: cover;
  margin-right: 8px;
}

.cei-status-active {
  background-color: rgb(22, 160, 134);
  color: #fff;
  font-size: 12px;
  padding: 4px 14px;
  border-radius: 4px;
  display: inline-block;
}

.corporate-explore-insight-view-all-companies {
  font-size: 14px;
  color: #00355f;
  text-decoration: none;
}

.corporate-explore-insight-view-all-companies:hover {
  text-decoration: underline;
}
.corporate-explore-insight-company-name-link {
  color: #00355f;
}

.corporate-explore-insight-companies-network-buttons {
  display: flex;
  justify-content: center;
}


@media (min-width: 360px) and (max-width: 400px) {
  .corporate-explore-insight-companies-network-buttons {
    display: flex;
    justify-content: center;
  }
}
