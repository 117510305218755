.login-section.feedback-section {
  padding: 50px 40px 32px;
  background: white;
  height: 100%;
  min-height: 100vh;
}

.login-section .login-form-div.feedback-form-div {
  max-width: 887px;
  width: 100%;
  margin: auto auto;
  background-color: #f5f5f5;
  padding: 32px 40px;
  border-radius: 10px;
}

.login-form-div.feedback-form-div .first-heading {
  margin-bottom: 15px;
}

.feedback-section .input-outer-div {
  max-width: 700px;
}
.FeedbackForm-text {
  font-size: 12px;
}
.feedback-section .parallel-div {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.feedback-section .buttons-div.submmit {
  max-width: 340px;
  width: 100%;
}

.feedback-section .buttons-div.submmit button {
  padding: 12px;
}
.FeedbackForm-title,
.FeedbackForm-email {
  font-size: 0.8rem;
  margin-right: 5px;
}
.FeedbackForm-submit-info-btn {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}

@media (min-width: 360px) and (max-width: 400px) {
  .FeedbackForm-submit-info-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    height: 40px;
  }
}

@media (max-width: 768.99px) {
  .feedback-section .parallel-div {
    flex-direction: column;
    gap: 0px;
  }
}

@media (max-width: 575.99px) {
  .login-section.feedback-section .login-form-div {
    padding: 16px;
  }

  .login-form-div.feedback-form-div .first-heading {
    margin-bottom: 16px;
  }

  .login-section.feedback-section {
    padding: 30px 20px 20px;
  }
}
