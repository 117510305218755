.corporate-links-section {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.corporate-links-section .inner-card {
  margin-top: 16px;
  flex: 1;
  border-radius: 10px;
  background-color: white;
  padding: 24px;
  min-width: 160px;
}

/* @media(max-width:768px) {
    .corporate-links-section .inner-card {
        min-width: 310px;
    }
} */

@media (max-width: 575px) {
  .corporate-links-section .inner-card {
    min-width: 230px;
  }
}

@media (max-width: 768px) {
  /* Adjust the styling for smaller screens here */
  .dashboard-content-div {
    max-width: 100%;
    overflow-x: hidden;
  }

  /* Optionally, you can adjust other styles as needed */
  .corporate-links-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inner-card {
    width: 100%;
    margin-bottom: 16px; /* Adjust the margin as needed */
  }
}
