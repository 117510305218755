.ch_options_sidebar {
  overflow: auto;
}

.ch_filter_sidebar_item {
  display: block;
  transition: background-color 0.15s;
  border-bottom: 1px solid #ece8e8;
  cursor: pointer;
}

.ch_filter_sidebar_title {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.ch_filter_sidebar_content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}

.ch_filter_sidebar_item.open > .ch_filter_sidebar_content {
  height: auto;
  margin-left: 5px;
}

.ch_filter_sidebar_item.open > .ch_filter_sidebar_title {
  background-color: gainsboro;
}

.ch_filter_sidebar_title:hover {
  background-color: gainsboro;
}

/* Add hover effect for individual content items */
.ch_filter_sidebar_content > div:hover {
  background-color: lightgray;
}

.ch_filter_sidebar_content_sub_item {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.ch_filter_sidebar_content_sub_item_blue_text {
  color: #0466d1;
}

.ch-filter-sidebar-item-icon-rotate {
  transform: rotate(90deg);
  transition: transform 0.3s ease;
}

.ch_filter_sidebar_title_disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
