
.corporate-explore-insight-common-info-card {
  padding: 40px;
  border-radius: 20px;
    border: 1px solid rgb(223, 222, 222);
  /* background-color: white; */
  /* max-width: 600px; */
  width: 100%;
  margin: 0 auto;
  margin-bottom: 5px;
}

.corporate-explore-insight-common-info-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.corporate-explore-insight-common-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.corporate-explore-insight-common-info-item h4 {
  font-size: 14px;
  font-weight: 600;
  color: gray;
  margin: 0 0 5px;
}

.corporate-explore-insight-common-info-item p {
  font-size: 14px;
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .corporate-explore-insight-common-info-grid {
    grid-template-columns: 1fr;
  }
}
