.profile-details-section {
  display: flex;
  align-items: flex-start;
  gap: 72px;
  background-color: white;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 24px;
}

.profile-details-section .outer-div div {
  margin-top: 8px;
  margin-bottom: 8px;
}

.profile-details-section .outer-div div {
  margin-right: 20px;
}

.profile-details-section .outer-div.custom-margin {
  margin-left: -45px;
}

.profile-details-section .outer-div.round-img-div {
  max-width: 56px;
  width: 100%;
  height: 56px;
  background-color: #f5f5f5;
  border-radius: 50%;
  align-self: center;
}

.label-text {
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.5em;
  color: #656565;
  margin-right: 16px;
}

@media (max-width: 1440.99px) {
  .profile-details-section {
    gap: 16px;
  }

  .profile-details-section .outer-div.custom-margin {
    margin-left: 0px;
  }
}

@media (max-width: 1024.99px) {
  .profile-details-section {
    gap: 0px;
    flex-direction: column;
  }

  .profile-details-section .outer-div.round-img-div {
    align-self: flex-start;
  }

  .profile-details-section .outer-div.custom-margin {
    margin-left: 0px;
  }
}
