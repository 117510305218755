.start-journey-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  color: #fff;
  width: 90%;
  height: 35vh;
  margin: 20px auto;
    background-image: linear-gradient(to right, rgba(2, 27, 65, 0.7), rgba(4, 102, 209, 0.7)) !important;
    background-position: left top !important;
    background-size: auto !important;
    background-repeat: no-repeat !important;
}

.start-journey-content {
  max-width: 70%;
}

.start-journey-content h4 {
  margin: 0 0 10px;
  font-weight: 400;
}

.start-journey-content h2 {
  margin: 0 0 15px;
  font-weight: 700;
  font-size: 32px;
}

.start-journey-content p {
  margin: 0;
  font-size: 16px;
}

.start-journey-button {
  padding: 10px 20px;
  background-color: #00d084;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.start-journey-button:hover {
  background-color: #00b874;
}
