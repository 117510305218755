.corporate-insights-result-card {
    background-color: white;
    border-radius: 12px;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 2px solid white;
}

.InsightCard-selected-card {
    border: 2px solid rgb(2, 156, 189) !important;
}

.corporate-insights-result-card:hover {
    background-color: rgb(230, 230, 230);
    cursor: pointer;
    border: 2px solid rgb(230, 230, 230);
}

.corporate-insights-result-card-content {
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.corporate-insights-result-card-content-header p {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #333;
    margin: 0;
}

.corporate-insights-result-card-body {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.corporate-insights-result-card-info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.corporate-insights-result-card-value {
    font-size: 10px;
    font-weight: 400;
    line-height: 0.7;
    color: #3D3D3D;
    text-align: left;
}

.corporate-insights-result-card-info {
    font-size: 10px;
    font-weight: 400;
    line-height: 1.8;
    color: #3D3D3D;
}

.corporate-insights-result-card-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.corporate-insights-result-card-header-left {
    display: flex;
    align-items: center;
}

.corporate-insights-result-card-header-name {
    font-size: 12px;
    font-weight: 600;
    color: #333;
}

.corporate-insights-result-card-header-flag {
    margin-left: 10px;
}

.corporate-insights-result-card-flag-icon {
    width: 15px;
    height: 10px;
}

.corporate-insights-result-card-header-right {
    display: flex;
    align-items: center;
}

/* Status styles */
.corporate-insights-result-card-status-active {
    background: linear-gradient(to bottom right, #0466D1, #00D1A9);
    color: white;
    border-radius: 3px;
    font-size: 0.875rem;
    font-style: italic;
}

.corporate-insights-result-card-status-inactive {
    color: white;
    border-radius: 3px;
    font-size: 0.875rem;
    font-style: italic;
}

/* Avatar styles */
.corporate-insights-result-card-avatar {
    width: 60px;
    height: 60px;
    background: linear-gradient(to bottom right, #0466D1, #00D1A9);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

/* Link styles */
.result-link {
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
}

.result-link:hover {
    text-decoration: underline;
}

.corporate-insights-result-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.corporate-insights-result-card-toggle-button {
    border: none;
    background-color: transparent;
    z-index: 999;
}

.corporate-insights-result-card-expanded-info {
    padding: 10px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
    animation: fadeIn 0.3s ease;
}

.corporate-insights-result-card-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/* Responsive Design */
@media (max-width: 992px) {
    .corporate-insights-result-card {
        width: 100%;
        padding: 15px;
    }

    .header-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .header-right {
        margin-top: 10px;
        gap: 10px;
    }

    .corporate-insights-result-card-info-row {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}