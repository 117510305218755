:root {
  --primary-color: #0466d1;
  --secondary-color: #00d1a9;
  --heading-dark-color: #021b41;
  --dark-text-color: #212529;
  --light-text-color: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body {
  font-size: 16px;
  line-height: 24px;
}

.primary-color {
  color: var(--primary-color);
}

.primary-background-color {
  background-color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.secondary-background-color {
  background-color: var(--secondary-color);
}

.light-text-color {
  color: var(--light-text-color);
}

.dark-heading-color {
  color: var(--heading-dark-color);
}

.dark-text-color {
  color: var(--dark-text-color);
}

.main-title {
  font-weight: 600;
  font-size: 4em;
  line-height: 1.5em;
}

.section-heading {
  font-weight: 600;
  font-size: 2.5em;
  line-height: 1.5em;
}

.heading-1 {
  font-weight: 600;
  font-size: 2em;
  line-height: 1.5em;
}

.heading-2 {
  font-weight: 600;
  font-size: 1.5em;
  line-height: 1.5em;
}

.heading-2-light {
  font-weight: 500;
  font-size: 1.5em;
  line-height: 1.5em;
}

.subtitle-1 {
  font-weight: 400;
  font-size: 2.125em;
  line-height: 1.5em;
}

.body-text-bold {
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.5em;
}

.small-text-bold {
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.5em;
}

.small-text-normal {
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.5em;
}

.body-text-normal {
  font-weight: 500;
  font-size: 1em;
  line-height: 1.5em;
}

.body-text-heading {
  font-weight: 600;
  font-size: 1em;
  line-height: 1.5em;
}

.body-text {
  font-weight: 400;
  font-size: 1.25em;
  line-height: 1.5em;
}

.small-text {
  font-weight: 400;
  font-size: 1.25em;
  line-height: 1.5em;
}

.button-text {
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.5em;
}

.button-text-bold {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.5em;
}

.caption-text {
  font-weight: 400;
  font-size: 0.75em;
  line-height: 1.5em;
}

.caption-text-bold {
  font-weight: 600;
  font-size: 0.75em;
  line-height: 1.5em;
}

@media (max-width: 1600.99px) {
  body {
    font-size: 14px;
  }
}

@media (max-width: 1440.99px) {
  /* .main-title {
    font-size: 56px;
    line-height: 72px;
  }

  .body-text {
    font-size: 18px;
    line-height: 32px;
  } */
  body {
    font-size: 12px;
  }
}

@media (max-width: 1024.99px) {
  /* .body-text {
    font-size: 16px;
    line-height: 24px;
  } */
  body {
    font-size: 10px;
  }
}

@media (max-width: 575.99px) {
  /* .main-title {
    font-size: 42px;
    line-height: 60px;
  } */

  /* .section-heading {
    font-size: 32px;
    line-height: 48px;
  } */
}

/* table thead {
  position: sticky;
  top: 0;
} */

@media (min-width: 344px) and (max-width: 768px) {
  .small-text {
  font-weight: 400;
  font-size: 1.9em;
  line-height: 1.5em;
  }
}