.corporate-links-graph-sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: #f4f4f4;
  transition: left 0.3s ease;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}
.corporate-links-graph-sidebar.open {
  left: 0;
}

.corporate-links-graph-sidebar-close-btn {
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  color: #007bff;
  text-align: right;
}

.corporate-links-graph-data-filters {
  padding: 20px;
}
.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu-item {
  display: flex;
  align-items: center;
  padding: 12px 10px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar-menu-item:hover {
  background-color: #e0e0e0;
}

.sidebar-menu-item.active {
  background-color: #007bff;
  color: white;
}
.sidebar-menu-item i {
  margin-right: 10px;
}
