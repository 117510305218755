.judgement_check_your_dtail_main_div_title {
  width: 30%;
}

.judgement_check_your_dtail_main_div_header {
  font-size: 15px;
  font-weight: 600;
}

.judgement_check_your_dtail_form_style {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
  margin-top: 5vh;
}

.judgement_check_your_dtail_main_div {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

@media (max-width: 1200px) {
  .judgement_check_your_dtail_main_div {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .judgement_check_your_dtail_form_style {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    margin-top: 5vh;
  }
}

@media (max-width: 402px) {
  .judgement_check_your_dtail_main_div_title {
    width: 50%;
  }
  .judgement_check_your_dtail_main_div_header {
    font-size: 10px;
    font-weight: 600;
  }
}
