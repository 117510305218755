.corporate-explore-insight-person-insights-container {
  width: 100%;
  border-radius: 8px;
  height: 50vh;
  overflow-y: auto;
}

/* WebKit scrollbar styles */
.corporate-explore-insight-person-insights-container::-webkit-scrollbar {
  width: 8px;
}

.corporate-explore-insight-person-insights-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.corporate-explore-insight-person-insights-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

.corporate-explore-insight-person-insights-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.corporate-explore-insight-person-insights-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.corporate-explore-insight-person-insights-title {
  margin-bottom: 15px;
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

.corporate-explore-insight-person-insights-main {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  justify-content: flex-end;
  background-color: #efeeee6e;
  transition: background-color 0.3s ease;
}

.corporate-explore-insight-person-insights-icon {
  color: #888;
  margin-right: 10px;
}

.corporate-explore-insight-person-insights-person-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.corporate-explore-insight-person-insights-person-detail-person-name {
  color: #00355f;
  text-align: center;
  display: flex;
  align-items: center;
}

.corporate-explore-insight-person-insights-main:last-child {
  margin-bottom: 0;
}

.corporate-explore-insight-person-insights-person-detail-person-main-div {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.corporate-explore-insight-person-insights-person-detail-person-main-div:hover {
  background-color: #f1f1f1;
}
