.corporete-links-Search-header {
  padding: 5px 10px;
  border-radius: 8px;
  width: 300px;
  border: 2px solid rgb(2, 156, 189);
  background-color: #fff;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 20px;
  white-space: nowrap;
  /* Prevent text from wrapping */
}

.corporete-links-Search-comapny-name a {
  color: '#000';
}

.corporete-links-Search-search-container {
  /* position: relative; */
  display: flex;
  align-items: center;
  flex-grow: 1;
  transition: width 0.3s ease-in-out;
  /* width: 350px; */
  /* Default width */
}

.corporete-links-Search-search-container.expanded {
  width: 100%;
}

.corporete-links-Search-search-input {
  position: relative;
  width: 100%;
  padding-left: 5%;
  border: none;
  border-radius: 25px;
  background-color: white;
  font-size: 16px;
  color: #0d99ff;
  outline: none;
  transition: width 0.3s ease-in-out;
  white-space: nowrap;
  /* Keeps text in one line */
  overflow: hidden;
  /* Hides overflow text */
  text-overflow: ellipsis;
  /* Adds "..." if text overflows */
}

.corporete-links-Search-search-input::placeholder {
  color: #0d99ff
}

.search-icon {
  border: none;
  background: none;
  cursor: pointer;
}

.search-icon img {
  height: 13.5px;
  width: 13.4px;
}

/* Mobile view: Show only the icon, and hide the input */
@media (max-width: 900px) {
  /* .corporete-links-Search-search-container {
    width: 40px;
    justify-content: flex-end;
  } */

  .corporete-links-Search-search-container.expanded {
    width: 100%;
  }

  /* .search-input {
    display: none;
  } */

  .corporete-links-Search-search-container.expanded .search-input {
    display: block;
    width: 100%;
  }

  /* .corporete-links-Search-comapny-name,
  .logo {
    display: none !important;
  } */

  .corporete-links-Search-header {
    padding: 12px;
  }


}