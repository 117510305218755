.navbar-section {
  padding: 10px 20px;
  margin: 0 auto;
  max-width: 1920px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar .navbar-nav {
  gap: 48px;
}

.navbar .navbar-nav .nav-item .nav-link {
  font-style: normal;
  font-weight: 400;
  font-size: 1.25em;
  line-height: 1.5em;
  color: white;
}

.navbar .navbar-nav .nav-item.try-probe-digital-btn {
  background-color: #00d1a9;
  color: black;
  border-radius: 4px;
  padding-right: 16px;
  padding-left: 16px;
}

.navbar .navbar-nav .nav-item.try-probe-digital-btn .nav-link {
  color: black;
}

.navbar-toggler-icon {
  filter: invert(1);
}

.mobile-logo {
  display: none;
}

@media (max-width: 1600.99px) {
  .navbar-section {
    padding: 5px 20px;
  }
}

@media (max-width: 768.99px) {
  .navbar-section {
    padding: 5px 12px;
  }
}

@media (max-width: 992.99px) {
  .navbar .navbar-nav {
    gap: 20px;
  }

  .navbar-collapse {
    margin-top: 20px;
    border-top: 1px solid #ffffff3d;
  }

  .mobile-logo {
    display: block;
  }

  .desktop-logo {
    display: none;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    text-align: center;
  }
}
