.explore-links-officerCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  margin: 20px auto;
  text-align: center;
}

.explore-links-officer-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.explore-links-officer-card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

.explore-links-officer-card-subtitle {
  font-size: 14px;
  margin-bottom: 16px;
}

.explore-links-officer-card-text {
  font-size: 16px;
  margin-bottom: 16px;
  text-align: left;
  font-weight: 400;
}

.accordion {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.explore-links-officer-accordion-header {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
}

.explore-links-officer-accordion-logo {
  width: 55px;
  height: 50px;
  margin-right: 12px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.explore-links-officer-accordion-logo img {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.explore-links-officer-accordion-title {
  flex: 1;
  text-align: left;
}

.explore-links-officer-accordion-main-title {
  font-size: 16px;
  font-weight: bold;
}

.explore-links-officer-accordion-subtitle {
  font-size: 12px;
}

.explore-links-officer-accordion-arrow {
  font-size: 10px;
  margin-left: 10px;
  margin-left: 14px;
  margin-bottom: 37px;
}

.explore-links-officer-accordion-content {
  padding: 15px;
}

.explore-links-officer-accordion-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.accordion-item-label {
  font-weight: bold;
}

.accordion-item-description {
  font-size: 0.9em;
  color: #888;
}

.accordion-item-value-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.accordion-item-value {
  font-weight: bold;
}

.price-input {
  border: none;
  background: none;
  font-size: 1em;
  text-align: right;
  width: 50px;
  margin-left: 5px;
}

.price-input:focus {
  outline: none;
}

.info-icon {
  font-size: 0.9em;
  color: #888;
  margin-left: 5px;
  cursor: pointer;
}

.accordion-separator {
  height: 1px;
  background-color: #f3f0f0;
  margin: 5px 0;
}

.accordion-item-description-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.accordion-item-description-container {
  display: flex;
  align-items: flex-start;
}

.accordion-item-label {
  margin-right: 5px;
}

.accordion-item-description {
  flex: 1;
}

@media (min-width: 360px) and (max-width: 768px) {
  .explore-links-officerCard {
    padding: 1px !important;
    margin: 5px !important;
  }
}
