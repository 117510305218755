.judgement_check_privacy_policy_consumer_complaints_heading {
  font-size: large;
  font-weight: 550;
}

.judgement_check_privacy_policy {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
  margin-top: 5vh;
  padding-bottom: 20px;
  gap: 20px;
}

.judgement_check_privacy_policy_contact_us {
  display: flex;
  flex-direction: column;
}

.judgement_check_privacy_policy_consumer_complaints {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.judgement_check_privacy_policy_make_complaint {
  display: flex;
  justify-content: center;
}

@media (max-width: 402px) or (max-width: 430px) {
  .judgement_check_privacy_policy {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
    margin-top: 5vh;
    padding-bottom: 20px;
    gap: 20px;
  }

  .judgement_check_privacy_policy_contact_us {
    display: flex;
    flex-direction: column;
  }

  .judgement_check_privacy_policy_consumer_complaints {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .judgement_check_privacy_policy_make_complaint {
    display: flex;
    justify-content: center;
  }

  .judgement_check_privacy_policy_consumer_complaints_heading {
    font-size: 10px;
    font-weight: bold;
  }
}

@media (max-width: 768px) or (max-width: 1024px) {
  .judgement_check_privacy_policy {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    margin-top: 5vh;
    padding-bottom: 20px;
    gap: 20px;
  }
}
