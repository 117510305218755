.corporate-explorer-property-domain-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 20px;
}

.corporate-explorer-property-domain-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.corporate-explorer-property-domain-count {
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 12px;
}

.corporate-explorer-property-striped-table table {
  width: 100%;
  border-collapse: collapse;
}

.corporate-explorer-property-striped-table th,
.corporate-explorer-property-striped-table td {
  padding: 10px;
  text-align: left;
}

.corporate-explorer-property-domain-title {
  font-size: 16px;
  border: 0;
  margin-top: 6px;
}
.corporate-explorer-property-domain-count {
  display: inline-block;
  padding: 0px 4px;
  border-radius: 4px;
  margin-left: 4px;
  font-size: 11px;
  font-weight: 600;
  background: #656e73;
  color: #fff;
  vertical-align: top;
  margin-top: 4px;
}
.corporate-explorer-property-domain-icon {
  margin-right: 8px;
  margin-bottom: 6px;
}

@media (max-width: 600px) {
  th,
  td {
    font-size: 12px;

    padding: 8px;
  }

  .corporate-explorer-property-domain-icon {
    margin-right: 4px;
  }
}
