.corporate-explorer-company-info-main {
  background-color: whitesmoke;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.company-info-modal-person-list-info-link-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}

.corporate-explorer-company-info-header {
  top: 0;
  z-index: 10;
  position: sticky;
  background-color: rgb(255, 255, 255);
  height: 45px;
  display: flex;
  align-items: center;
  font: 13px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.corporate-explorer-company-info-body {
  background-color: white;
  margin: 10px;
}
.corporate-explorer-company-info-navs {
  display: flex;
  justify-content: space-between;
  flex: 0.4;
  margin-left: 20px;
}

.corporate-explorer-company-info-navs div {
  position: relative;
  color: #00355f;
}

.corporate-explorer-company-info-navs div:hover {
  cursor: pointer;
}

.corporate-explorer-company-info-navs div:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px; /* 3px below the text */
  width: 100%;
  border-bottom: 4px solid #0073ce;
}

.corporate-explorer-company-info-navs .active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  border-bottom: 4px solid #0073ce;
}
/* contact */
.company-info-modal-overview-main {
  padding: 15px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.company-info-modal-overview-title {
  font-size: 16px;
  font-weight: 500;
  color: #021b41;

  margin-bottom: 20px;
}

.company-info-modal-overview-contant {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 1px 65px;
}

.company-info-modal-overview-contant-one {
  display: contents;
}

.company-info-modal-overview-key {
  font-size: 11px;
  display: flex;
  gap: 5px;
  font-weight: 500;
}

@media (min-width: 344px) and (max-width: 400px) {
  .company-info-modal-overview-key {
    margin-left: -10px;
    display: flex;
    /* width: 100%; */
  }
}
@media (min-width: 400px) and (max-width: 820px) {
  .company-info-modal-overview-key {
    margin-left: -10px;
    display: flex;
    width: 100%;
  }
}

@media (min-width: 820px) and (max-width: 1024px) {
  .company-info-modal-overview-key {
    margin-left: -10px;
    display: flex;
    width: 100%;
    align-items: flex-start;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .company-info-body-people-contact-contant {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
  }

  .company-info-modal-overview-key,
  .company-info-modal-overview-value1 {
    flex: 1 1 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .company-info-modal-overview-key {
    display: flex;
    min-height: fit-content;
    align-items: center;
    width: 100%;
  }
  .company-address {
    margin-left: 30px !important;
  }
}

.company-info-modal-overview-value {
  font-weight: 400;
  font-size: 12px;
  color: rgb(114, 114, 114);
  display: flex;
}

.company-info-body-scroller {
  height: 70vh;
  overflow-y: auto;
}

.company-info-body-scroller-insight {
  height: 40vh;
  overflow-y: auto;
}

.company-info-body-people-contact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.company-info-body-people-contact-contant {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.company-info-modal-people-contant-main {
  margin: 10px;
  background-color: white; /* Set the background color to white */
  border-radius: 1px; /* Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.company-info-modal-people-officers-count {
  display: flex;
  width: 14px;
  height: 14px;
  background-color: grey;
  color: white;
  font-size: 11px;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
}

.company-info-modal-person-list {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  margin: 1px;
  border-radius: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.company-info-modal-person-list-is-card-view {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  margin: 1px;
  border-radius: 1px;
  border: 1px solid gainsboro;
  align-items: center;
}

.company-info-modal-person-list-is-card-view:hover {
  background-color: #f2f2f2;
}

.company-info-modal-person-list-info {
  display: flex;
  /* gap: 4px; */
  width: 100%;
  flex-direction: column;
}


/* Select every 2nd element starting from the 1st one */
.company-info-modal-person-list:nth-child(odd) {
  background-color: white;
}

/* Select every 2nd element starting from the 2nd one */
.company-info-modal-person-list:nth-child(even) {
  background-color: rgb(250, 250, 250);
}

.company-info-modal-overview-value-with-flex {
  display: flex;
  gap: 5px;
}

.company-info-modal-overview-value-with-flex > *:not(:last-child)::after {
  content: '•';
  margin-left: 1px;
  margin-right: 1px;
}
.company-info-modal-overview{
  display: flex;
}
@media (min-width: 360px) and (max-width: 400px) {
  .corporate-explorer-company-info-navs {
    flex: 0.9;
    gap: 2px;
  }

  .company-info-modal-overview-value-people-list {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .company-info-modal-dot {
    display: none;
  }

  .company-info-modal-overview-value-with-flex {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 400px) and (max-width: 991px) {
  .corporate-explorer-company-info-navs {
    flex: 0.9;
  }

  .company-info-modal-overview-value-people-list {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .company-info-modal-dot {
    display: none;
  }

  .company-info-modal-overview-value-with-flex {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 420px) and (max-width: 768px) {
  .corporate-explorer-company-info-navs {
    flex: 0.8;
    gap: 2px;
  }

  .company-info-modal-overview-value-people-list {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .company-info-modal-dot {
    display: none;
  }

  .company-info-modal-overview-value-with-flex {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 240px) and (max-width: 420px) {
  .corporate-explorer-company-info-navs {
    flex-wrap: wrap;
    /* margin: 5px; */
    gap: 6px;
  }
  .corporate-explorer-company-info-header {
    height: auto;
  }
  .company-info-modal-overview-value-people-list {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .company-info-modal-dot {
    display: none;
  }

  .company-info-modal-overview-value-with-flex {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 100px) and (max-width: 250px) {
  .corporate-explorer-company-info-navs {
    flex-wrap: wrap;
    gap: 5px;
  }

  .corporate-explorer-company-info-header {
    height: auto;
  }
  .company-info-modal-overview-value-people-list {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .company-info-modal-dot {
    display: none;
  }

  .company-info-modal-overview-value-with-flex {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 991px) and (max-width: 12000px) {
  .corporate-explorer-company-info-navs {
    flex: 0.5;
  }
}

@media (min-width: 991px) and (max-width: 1400px) {
  .corporate-explorer-company-info-navs {
    flex: 0.5;
  }

  .company-info-modal-overview-value-people-list {
    display: flex;
    flex-direction: row;
    gap: 0px;
  }

  .company-info-modal-overview-value-with-flex {
    display: flex;
    align-items: center;
  }
}

.company-info-modal-Table-container {
  width: 100%;
  margin: 0;
}

.ordinary-heading {
  color: white;
}

.company-info-modal-share-type {
  margin-bottom: 20px;
}

.company-info-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.company-info-modal-shares-type {
  font-weight: bold;
}

.company-info-modal-total-shares {
  display: flex;
  align-items: center;
}

.company-info-modal-shareholders-table {
  width: 100%;
  overflow: auto;
  border-collapse: collapse;
}

.company-info-modal-shareholders-table th,
.company-info-modal-shareholders-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.company-info-modal-shareholders-table th {
  background-color: #f2f2f2;
  text-align: left;
}
/* 
.company-info-modal-shareholders-name.sub-text {
  font-size: 0.4em;
  color: #666;
} */

.company-info-modal-shareholder-socialIcons {
  display: flex;
  gap: 1px;
  width: 10px;
  text-align: center;
}

.company-info-modal-shareholder-socialIcons-insights {
  flex-direction: column;
}

.company-info-modal-shareholder-socialIcons-size {
  font-size: 14px !important;
}

.company-info-modal-shareholders-table .percentage-column {
  width: 80px;
  text-align: center;
}
.shares-column {
  width: 40px;
  text-align: center;
}

.company-info-modal-shareholders-table .percentage-column {
  width: 80px;
  text-align: center;
}

.company-info-modal-shareholders-table .preferred-column {
  width: 100px;
  text-align: center;
}

.company-info-modal-shareholders-name {
  font-weight: bold;
  width: 75%;
}

.company-info-modal-shareholders-name .sub-text {
  font-weight: lighter;
  color: #666;
  font-size: 0.8rem;
}

@media (min-width: 100px) and (max-width: 1000px) {
  .company-info-modal-shareholder-socialIcon-main {
    display: none;
  }
}

/* contact css here  */
.company-info-body-people-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.company-info-body-people-contact-contant {
  flex: 1 1 45%;
  margin-bottom: 20px;
}

.company-info-modal-overview-key,
.company-info-modal-overview-value1 {
  display: flex;
  /* align-items: center; */
  margin-bottom: 10px;
}
@media (min-width: 344px) and (max-width: 768px) {
  .company-info-body-people-contact {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: -145px;
    gap: 1px;
  }

  .company-info-modal-person-list-info-link-buttons {
    justify-content: flex-start;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .company-info-body-people-contact {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: -145px;
    gap: 1px;
  }
}
@media (min-width: 800px) and (max-width: 1200px) {
  .company-info-modal-overview-value1 {
    display: flex;
    justify-content: space-between;
    margin-left: 31px;
  }

  .company-info-modal-shareholders-name {
    font-weight: bold;
    width: 65%;
  }
}
.company-info-modal-overview-key {
  font-weight: bold;
}

@media (max-width: 768px) {
  .company-info-body-people-contact {
    display: flex;
    justify-content: space-around;
  }

  .company-info-body-people-contact-contant {
    flex: 1 1 100%;
  }

  .company-info-modal-overview-key,
  .company-info-modal-overview-value1 {
    margin-bottom: 5px;
    margin-left: -3px;
  }
}

/* .people-person-name-social-icons{
display: flex;
justify-content: space-around;
} */
