.who-we-help-section {
  padding: 72px 160px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 72px;
}

.who-we-help-section .first-heading {
  position: relative;
  margin-bottom: 44px;
}

.who-we-help-section .first-heading::after {
  position: absolute;
  content: "";
  height: 4px;
  width: 120px;
  background-color: #00d1a9;
  bottom: -6px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.who-we-help-section .cards-outer-div {
  margin-top: 88px;
  display: flex;
  gap: 32px;
  align-items: flex-start;
}

.who-we-help-section .cards-outer-div .cards-div {
  text-align: center;
  height: 550px;
}

.who-we-help-section .cards-outer-div .cards-div img {
  margin-bottom: 24px;
  max-width: 148px;
  width: 100%;
}

.who-are-we-section {
  padding: 72px 160px;
  margin: 0 auto;
  max-width: 1920px;
  width: 100%;
  background: rgba(4, 102, 209, 0.05);
}

.who-are-we-section .outer-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.who-are-we-section .outer-div .left-div {
  max-width: 780px;
  width: 100%;
}

.who-are-we-section .outer-div .left-div .first-heading {
  position: relative;
}

.who-are-we-section .outer-div .left-div .first-heading::after {
  position: absolute;
  content: "";
  height: 4px;
  width: 120px;
  background-color: #00d1a9;
  bottom: -6px;
  left: 0;
}

.who-are-we-section .outer-div .right-div {
  max-width: 750px;
  width: 100%;
}

.who-are-we-section .outer-div .right-div img {
  width: 100%;
}

.what-we-do-section {
  padding: 72px 160px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 72px;
}

.what-we-do-icon {
  width: 10px;
  height: 50px;
}

.what-we-do-section .first-heading {
  position: relative;
  margin-bottom: 44px;
}

.what-we-do-section .first-heading::after {
  position: absolute;
  content: "";
  height: 4px;
  width: 120px;
  background-color: #00d1a9;
  bottom: -6px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.what-we-do-section .cards-outer-div {
  margin-top: 88px;
  display: flex;
  gap: 32px;
  height: auto;
  align-items: center;
  justify-content: center;
}

.what-we-do-section .cards-outer-div .cards-div {
  text-align: center;
  max-width: 500px;
  width: 100%;
  height: 550px;
}

.what-we-do-section .cards-outer-div .cards-div img {
  margin-bottom: 24px;
  max-width: 148px;
  width: 100%;
}

.what-we-do-section .counter-outer-div {
  padding: 40px 72px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 72px 0;
}

.our-qualities-section {
  padding: 72px 160px;
  margin: 0 auto;
  max-width: 1920px;
  width: 100%;
  background: rgba(0, 209, 169, 0.05);
}

.our-qualities-section .our-services-outer-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 72px;
}

.our-qualities-section .our-services-outer-div .image-div {
  max-width: 448px;
  width: 100%;
}

.our-qualities-section .our-services-outer-div .image-div img {
  width: 100%;
}

.our-qualities-section .our-services-outer-div .left-div {
  max-width: 730px;
  width: 100%;
}

.our-qualities-section .our-services-outer-div .left-div .first-heading {
  position: relative;
  margin-bottom: 44px;
}

.our-qualities-section .our-services-outer-div .left-div .first-heading::after {
  position: absolute;
  content: "";
  height: 4px;
  width: 120px;
  background-color: #00d1a9;
  bottom: -6px;
  left: 0;
}

.signup-free-section {
  padding: 72px 160px;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  background: linear-gradient(99.78deg, #0466d1 0%, #00d1a9 152.3%), #0466d1;
}

.signup-free-section button {
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  margin-top: 32px;
}

@media (max-width: 1600.99px) {
  .who-we-help-section {
    padding: 72px 60px;
  }

  .who-are-we-section {
    padding: 72px 60px;
  }

  .what-we-do-section {
    padding: 72px 60px;
    margin-bottom: 0;
  }

  .our-qualities-section {
    padding: 72px 60px;
  }

  .signup-free-section {
    padding: 72px 60px;
  }

  .who-we-help-section .cards-outer-div .cards-div img {
    max-width: 100px;
  }

  .what-we-do-section .cards-outer-div .cards-div img {
    margin-bottom: 24px;
    max-width: 100px;
    width: 100%;
  }

  .what-we-do-section .counter-outer-div {
    gap: 20px;
  }
}

@media (max-width: 1366.99px) {
  .what-we-do-section .counter-outer-div div {
    min-width: fit-content;
  }

  .what-we-do-section .counter-outer-div {
    padding: 40px 32px;
  }
}

@media (max-width: 1320.99px) {
  .what-we-do-section .counter-outer-div div {
    min-width: auto;
  }
}

@media (max-width: 1024.99px) {
  .who-we-help-section .cards-outer-div,
  .what-we-do-section .cards-outer-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
  }

  .who-are-we-section .outer-div {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .our-qualities-section .our-services-outer-div {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
  }

  .our-qualities-section .our-services-outer-div ul {
    list-style-type: none;
  }

  .our-qualities-section .our-services-outer-div.second-divv {
    flex-direction: column-reverse;
  }

  .what-we-do-section .counter-outer-div {
    flex-direction: column;
    text-align: center;
  }

  .our-qualities-section
    .our-services-outer-div
    .left-div
    .first-heading::after {
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
  }
}

@media (max-width: 768.99px) {
  .who-we-help-section {
    padding: 25px 24px;
    margin-bottom: 0px;
  }

  .who-are-we-section {
    padding: 25px 24px;
  }

  .what-we-do-section {
    padding: 25px 24px;
  }

  .what-we-do-section .counter-outer-div {
    margin: 24px 0;
  }

  .our-qualities-section {
    padding: 25px 24px;
  }

  .signup-free-section {
    padding: 25px 24px;
  }
}
