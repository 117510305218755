.footer-section {
  padding: 72px 160px;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  
  justify-content: space-between;
}

.footer-section .first-div {
  max-width: 400px;
}

.footer-section .first-div img {
  margin-bottom: 24px;
}

.footer-section .first-div .footer-social-icons a {
  margin-right: 16px;
}

.footer-section .third-div .outer-div {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.footer-section .third-div .heading-2 {
  margin-bottom: 24px; 
}

.footer-section .small-text {
  font-size: 16px; 
  color: #9a9a9a; 
}

.footer-section .dark-heading-color {
  font-weight: bold;
  font-size: 18px;
}

@media (max-width: 1600.99px) {
  .footer-section {
    padding: 72px 60px;
  }
}

@media (max-width: 1024.99px) {
  .footer-section {
    flex-direction: column;
  }
}

@media (max-width: 768.99px) {
  .footer-section {
    padding: 25px 24px;
  }
}
