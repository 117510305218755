.cl-graph-highlighted-node {
  stroke: rgb(8, 152, 236);
  stroke-width: 2px;
  stroke-dasharray: 4, 2;

  /* Add blinking effect */
  animation: blink 1s infinite;
  /* 1s duration, infinitely repeating */
}

.cl-graph-desktop-vieww {
  display: none;
}

.cl-graph-LeftContainer {
  display: flex;
  justify-content: center;
  padding: 0px 16px;
  border: 1px solid #D0D5DD;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.cl-graph-LeftButton-main {
  display: flex;
  align-items: center;
  padding: 6px 0px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  margin: 0 12px;
}

.cl-graph-LeftButton-main-icon {
  margin-right: 8px;
  width: 10.84px;
  /* Adjust size */
  height: 16.92px;
}

.cl-graph-LeftButton-export-icon {
  margin-right: 8px;
  width: 19.2px;
  /* Adjust size */
  height: 18.95px;
}

.cl-graph-LeftButton-main-active {
  display: flex;
  align-items: center;
  background-color: #0380C7;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  padding: 0 12px;
  margin: 0 12px;
}


.cl-graph-RightContainer {
  display: flex;
  gap: 10px;
  flex-direction: row;
  background-color: transparent;
}

.cl-graph-connection-level {
  padding: 5px 10px;
  border-radius: 8px;
  border: 2px solid rgb(2, 156, 189);
  font-size: 16px;
  color: #0d99ff;
  text-overflow: ellipsis;
}

/* Container for the entire component */
.connection-level-container {
  position: relative;
}

/* Header that toggles the slider */
.connection-level-header {
  border: 2px solid rgb(2, 156, 189);
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  font-size: 16px;
  color: #0d99ff;
}

/* Icon next to the text */
.connection-level-icon {
  margin-left: 10px;
}

/* Container for the slider */
.connection-level-slider-container {
  position: absolute;
  right: 0;
  z-index: 10;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Vertical slider styling */
.connection-level-slider-vertical {
  writing-mode: bt-lr;
  /* Make slider vertical */
  -webkit-appearance: slider-vertical;
  /* For WebKit browsers */
  width: 8px;
  height: 120px;
  margin: 5px 0;
  background: #e0e0e0;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
}

.connection-level-slider-vertical::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
}

.connection-level-slider-vertical::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
}

.connection-level-slider-vertical::-ms-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #007bff;
  cursor: pointer;
}

/* Value below the slider */
.connection-level-value {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}


/* Keyframes for blink effect */
@keyframes blink {
  0% {
    stroke-opacity: 1;
    /* Fully visible at the start */
  }

  50% {
    stroke-opacity: 0;
    /* Invisible halfway through */
  }

  100% {
    stroke-opacity: 1;
    /* Fully visible again */
  }
}

.cl-graph-normal-node {
  stroke: #999;
}

.cl-graph-d3-svg {
  width: 100vw;
  height: 100vh;
}

.cl-graph-mobile-view {}

.cl-graph-searchinput-main {
  width: 100%;
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  padding: 0 37px;
}

.cl-graph-rightsidebarmenu-main {
  position: absolute;
  bottom: 10px;
  right: 50%;
  left: 50%;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
}

.cl-graph-modal-container {
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  color: black;
  padding-bottom: 0;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.cl-graph-modal-header {
  width: 75%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cl-graph-modal-filters {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.cl-graph-modal-filter {
  width: 80%;
  display: flex;
  gap: 8px;
  font-size: 14px;
  line-height: 21px;
}

.cl-graph-modal-filter-button {
  background-color: #999;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  border-radius: 24px;
  padding: 5px 20%;
}

@media (min-height: 650px) {
  .cl-graph-d3-svg {
    width: 100vw;
    height: 87vh;
  }
}

@media (min-height: 1000px) {
  .cl-graph-d3-svg {
    width: 100vw;
    height: 87vh;
  }
}

@media (min-height: 2000px) {
  .cl-graph-d3-svg {
    width: 100vw;
    height: 94vh;
  }
}

@media (min-height: 3000px) {
  .cl-graph-d3-svg {
    width: 100vw;
    height: 96vh;
  }
}

@media (min-height: 4000px) {
  .cl-graph-d3-svg {
    width: 100vw;
    height: 97vh;
  }
}

@media (min-height: 5000px),
(min-height: 7000px),
(min-height: 8000px) {
  .cl-graph-d3-svg {
    width: 100vw;
    height: 98vh;
  }
}

@media (min-height: 9000px) {
  .cl-graph-d3-svg {
    width: 100vw;
    height: 99vh;
  }
}

@media (min-width: 100px) and (max-width: 650px) {
  .cl-graph-mobile-view {
    display: block;
  }

  .cl-graph-searchinput-main {
    position: absolute;
    top: 1%;
    left: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .cl-graph-leftsidebar-itemholder {
    position: absolute;
    top: 75vh;
    left: 10;
  }

  .cl-graph-total-dropdown {
    position: absolute;
    top: 62vh;
    left: 10;
  }
}

@media (min-width: 769px) {
  .cl-graph-modal-container {
    display: none;
  }
}

@media (max-width: 768.99px) {
  .cl-graph-searchinput-main {
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .cl-graph-RightContainer {
    flex-direction: column;
  }
}

@media (max-width: 530px) {
  .cl-graph-rightsidebarmenu-main {
    bottom: 0px;
    right: 0px;
    left: 0px;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    padding-top: 5%;
    border-radius: 16px;
  }
}