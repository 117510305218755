/* Custom Scrollbar Styles */
.corporate-explorer-property-main-content {
  overflow-y: auto;
  height: 70vh;
}

.corporate-explorer-property-main-content::-webkit-scrollbar {
  width: 12px;
  background-color: #f1f1f1;
}

.corporate-explorer-property-main-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.corporate-explorer-property-main-content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.corporate-explorer-property-main-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Firefox Scrollbar Styles */
.corporate-explorer-property-main-content {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

/* .corporate-explorer-property-land-building-striped-table tr {
  background: #fafafa;
} */
.pagination {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.pagination-list {
  list-style: none;
  display: flex;
  padding: 0;
}

.pagination-item {
  margin: 0 5px;
}

.pagination-link {
  display: block;
  padding: 8px 12px;
  background-color: #f1f1f1;
  color: #000;
  text-decoration: none;
  border-radius: 4px;
}

.pagination-item.active .pagination-link {
  background-color: #007bff;
  color: #fff;
}

.pagination-link:hover {
  background-color: #007bff;
  color: #fff;
}
