/* Container for the entire card */
.explore-links-officerCard {
  width: 369px;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
}

/* Close button */
.explore-links-officer-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Header and icon alignment */
.explore-links-officer-accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
}

/* Icon container - fixed size of 60px by 60px */
.explore-links-officer-accordion-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: linear-gradient(to bottom right, #0466D1, #00D1A9);
  color: white;
  border-radius: 10px;
  margin-right: 15px;
}

/* Text on the right side */
.explore-links-officer-accordion-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

}

/* Title and role alignment */
.explore-links-officer-accordion-main-title,
.explore-links-officer-accordion-subtitle {
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: #333;
  text-align: left;
}

.explore-links-officer-accordion-status {
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: #DB0128;
  text-align: left;
}

/* Arrow indicating expansion/collapse */
.explore-links-officer-accordion-arrow {
  font-size: 10px;
  margin: 0;
}

/* Content when accordion is expanded */
.explore-links-officer-accordion-content {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
}

.accordion {
  border: none;
}

.accordion-separator {
  border-bottom: 1px solid #ddd;
  margin-top: 10px;
}