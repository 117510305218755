.corporate-explorer-property-trade-card{
 background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 20px ;
}

.corporate-explorer-property-trade-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.corporate-explorer-property-trade-striped-table table {
  width: 100%;
  border-collapse: collapse;
}

.corporate-explorer-property-trade-striped-table th, 
.corporate-explorer-property-trade-striped-table td {
  padding: 10px;
  text-align: left;
}

.corporate-explorer-property-trade-website-icon{
  margin-right: 8px;
}

.corporate-explorer-property-title {
  font-size: 16px;
  border: 0;
  margin-top: 6px;
}

.corporate-explorer-property-trade-striped-table {
  animation: none !important;
  padding: 10px 20px;
  max-height: 200px;
  overflow-y: auto;
}

/* Customizing the scrollbar */
.corporate-explorer-property-trade-striped-table::-webkit-scrollbar {
  width: 12px !important; 
}

.corporate-explorer-property-trade-striped-table::-webkit-scrollbar-thumb {
 background-color: #888!important;
  border-radius: 10px !important; 
}

.corporate-explorer-property-trade-striped-table::-webkit-scrollbar-track {
  background-color: #f1f1f1 !important;
  border-radius: 10px !important; 
}
.corporate-explorer-property-trade-striped-table {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1; 
}

th,
td {
  padding: 12px 16px;
  text-align: left;
  border: none;
}

th {
  background-color: #fff;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  border-bottom: none;
}

tbody tr:last-child td {
  border-bottom: none;
}

@media (max-width: 600px) {
  th,
  td {
    font-size: 12px;
    padding: 8px;
  }

  .corporate-explorer-property-trade-website-icon {
    margin-right: 4px;
  }
}
