.corporate-explore-person-insights-bio {
  width: 100%;
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  background-color: #fff;
}

.corporate-explore-insights-person-icon {
  background-color: #fcfbfb;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
}

.cei-person-icon svg {
  font-size: 40px;
  color: #333;
}

.corporate-explore-insights-person-name {
  display: flex;
  justify-content: flex-start;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0 0px 10px;
  text-align: left;
}

.corporate-explore-insights-person-details {
  font-size: 1em;
  display: flex;
  justify-content: flex-start;
  color: #555;
  font-size: 0.9em;
  margin: 0;
  text-align: left;
}
