.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.tooltip-box {
  position: absolute;
  padding: 5px 10px;
  background-color: #0078d7;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
}

/* Add this new class to ensure always visible tooltips */
.tooltip-visible {
  opacity: 1 !important;
}

.tooltip-top {
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-bottom {
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-left {
  top: 50%;
  right: 125%;
  z-index: 1;
  transform: translateY(-50%);
}

.tooltip-right {
  top: 50%;
  left: 125%;
  transform: translateY(-50%);
}

.tooltip-box::after {
  content: "";
  position: absolute;
  border-style: solid;
}

.tooltip-top::after {
  top: 100%;
  left: 50%;
  border-width: 5px 5px 0 5px;
  border-color: #0078d7 transparent transparent transparent;
  transform: translateX(-50%);
}

.tooltip-bottom::after {
  bottom: 100%;
  left: 50%;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #0078d7 transparent;
  transform: translateX(-50%);
}

.tooltip-left::after {
  left: 100%;
  top: 50%;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #0078d7;
  transform: translateY(-50%);
}

.tooltip-right::after {
  right: 100%;
  top: 50%;
  border-width: 5px 5px 5px 0;
  border-color: transparent #0078d7 transparent transparent;
  transform: translateY(-50%);
}
