.ch_exp_applied_filter_group_main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.corporate_explor_filter_modal_main_right_apply_filter_button {
  margin: 5px;
  display: flex;
  background-color: #305c8a;
  color: white;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.corporate_explor_filter_modal_main_right_apply_filter_button:hover {
  box-shadow: 0 12px 14px rgba(255, 113, 113, 0.1);
}

.ch_exp_applied_filter_group_main_filter_holder {
  overflow-y: auto;
  max-height: 80%;
}

.applied-filter-group-filter-item:hover .remove-button {
  visibility: visible !important;
}

@media (max-width: 360px) {
  .corporate_explor_filter_modal_main_right_apply_filter_button {
    width: 60px;
    font-size: smaller;
  }
}
