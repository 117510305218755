* {
  font-family: 'Poppins', sans-serif !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.header-fixed {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.content-area {
  position: relative;
  padding-top: 0.1px;
  overflow-y: auto;
}
