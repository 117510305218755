.person-filter-search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.company-person-advance-filter-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.company-person-advance-filter-col {
    width: 31%;
}

@media (max-width: 430px) {
    .company-person-advance-filter-row {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .company-person-advance-filter-col {
        width: 100%;
    }
}