.probe-stimulus-grid-row:hover {
  --bs-table-bg: rgb(235, 227, 227);
  background-color: var(--bs-table-bg);
}

.disambiguation-item-info-icon {
  width: 15px;
  height: 15px;
  color: #0466d1;
}

.yes-tag {
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.5em;
  color: #212529;
  background: rgba(0, 209, 109, 0.05);
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
  border-radius: 6px;
}

.no-tag {
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.5em;
  color: #212529;
  background: rgba(219, 1, 40, 0.05);
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
  border-radius: 6px;
}

.stimulus-info-section {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}

.stimulus-info-section .header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: white;
  /* color: black; */
}

.stimulus-info-section .table-custom thead {
  font-weight: 500;
  font-size: 0.875em;
  line-height: 1.5em;
  color: #656565;
}

/* .stimulus-info-section .table-custom .thead-td-custom {
  display: flex;
} */

.stimulus-info-section .table-custom thead tr td {
  border-bottom-width: 0px;
}

/* .stimulus-info-section .table-custom thead .img-thead {
  margin-bottom: 2px;
} */

.stimulus-info-section .table-custom td {
  padding: 12px 24px;
}

.heading-table {
  padding: 24px;
}

.small_description {
  font: 8px ;
}

@media (max-width: 768.99px) {
  .stimulus-info-section .table-custom td {
    padding: 12px 12px;
    /* font-size: 14px;
        line-height: 18px; */
    text-align: center;
  }

  .table-responsive {
    max-width: 600px;
  }
}
@media (min-width: 344px) and (max-width: 400px) {
  #jc-business-probe-button-search-ch-api {
    
  }
}

.thead-td-custom {
  display: flex;
  align-items: center;
}

.thead-td-custom img {
  margin-right: 5px;
}

@media (max-width: 360px)and (max-width: 400px) {
  .thead-td-custom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; /* Optional: to center content horizontally */
    margin-right: 10px; /* Space between headings */
  }

  .thead-td-custom span {
    white-space: nowrap; /* Prevent text from wrapping to the next line */
  }

  .creation-date {
    margin-left: 5px; /* Adjust space between words */
  }
  .probe-stimulus-grid-row{
     margin: 0 !important;
  padding: 0 !important;
  }
}
/* Ensure there is no spacing between cells but keep the borders */
table {
  border-collapse: collapse; /* Merge borders and remove spacing between cells */
  width: 100%; /* Ensure the table takes full width */
}

th, td {
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  border: 1px solid #ddd; /* Add borders to cells */
}

/* Optional: Adjust styles for specific class elements if needed */
.body-text-bold {
  font-weight: bold;
}

.thead-td-custom {
  display: flex;
  align-items: center;
 
}

.mb-4 {
  margin-bottom: 0; 
}

