.prob-label-container {
  border-radius: 10px;
}

.prob-label-text {
  font-size: 16px;
  color: #3D3D3D;
  font-weight: 400;
  line-height: 24px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .prob-label-text {
    font-size: 17px;
  }
}